/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React from "react";
import { CheckIcon } from "@heroicons/react/24/solid";

import { classNames } from "src/Hooks/util";

const Circles = ({ className, ...props }) => {
  return (
    <nav
      aria-label="Progress"
      className={`${className} m-auto text-center h-20`}
    >
      <ol className="flex flex-row w-full mx-0">
        {props.steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== props.steps.length - 1 ? "pr-8 sm:pr-20" : "",
              "relative w-full flex h-20"
            )}
          >
            {step.status === "complete" ? (
              <div className="absolute inset-0 flex w-full h-20 flex-grow-default">
                <div className="flex justify-center w-8 h-8 my-auto rounded-full bg-darkmode-700 bg-opacity-25">
                  <CheckIcon className="w-4 h-4 m-auto text-gray-400" />
                </div>
                <p className="hidden my-auto ml-3 text-xs whitespace-nowrap md:flex">
                  {step.name}
                </p>
                <div className="h-0.5 w-9 mx-auto bg-darkmode-700 my-auto" />
              </div>
            ) : step.status === "current" ? (
              <div className="absolute inset-0 flex w-full h-20 flex-grow-default">
                <div className="flex justify-center w-8 h-8 my-auto bg-yellow-500 rounded-full  bg-opacity-25">
                  <p className="m-auto text-yellow-500">{stepIdx + 1}</p>
                </div>
                <p className="hidden my-auto ml-3 text-xs text-yellow-500 whitespace-nowrap md:flex">
                  {step.name}
                </p>
                <div className="h-0.5 w-9 mx-auto bg-darkmode-700 my-auto" />
              </div>
            ) : (
              <div className="absolute inset-0 flex w-full h-20 flex-grow-default">
                <div className="flex justify-center w-8 h-8 my-auto rounded-full bg-darkmode-700 bg-opacity-25">
                  <p className="m-auto">{stepIdx + 1}</p>
                </div>
                <p className="hidden my-auto ml-3 text-xs whitespace-nowrap md:flex">
                  {step.name}
                </p>
                {stepIdx !== 2 && (
                  <div className="h-0.5 w-9 mx-auto bg-darkmode-700 my-auto" />
                )}
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Circles;
