/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";

const DisplayTrack = ({
  currentTrack,
  audioRef,
  setDuration,
  progressBarRef,
}) => {
  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  return (
    <div>
      <audio
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        crossOrigin="anonymous"
      >
        <source src={currentTrack} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
};
export default DisplayTrack;
