import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

const Banner = ({ ...props }) => {
  const handleClick = () => {
    props.setOpen(false);
    if (props.handleClose) props.handleClose();
  };

  return (
    <div className="flex items-center gap-x-6 bg-gray-300 dark:bg-newdarkmode-700 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <button type="button" className="" onClick={() => handleClick()}>
        <p className="m-auto text-xs font-thin text-left text-gray-900 dark:text-white sm:text-sm md:text-center leading-6">
          {props.children}
        </p>
      </button>
      <div className="flex justify-end flex-1">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          onClick={() => handleClick()}
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon
            className="w-5 h-5 text-gray-900 dark:text-white"
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  );
};

export default Banner;
