import React from "react";

import { classNames, omitKeys } from "src/Hooks/util";

const TextArea = ({ className = "", ...props }) => (
  <div className="my-4">
    <p className="mb-1 text-sm font-light text-left text-gray-800 dark:text-gray-500">
      {props.label}
    </p>
    <textarea
      className={classNames(
        className,
        "bg-gray-200 focus:bg-gray-300 rounded-xl text-sm xs:text-base focus:outline disabled:opacity-50 disabled:cursor-not-allowed mx-auto focus:text-gray-900 placeholder-gray-600 dark:placeholder-gray-600 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-600 focus:ring-darkmode-600 border-none focus:ring-1",
        props.darkest ? "dark:bg-newdarkmode-800" : "dark:bg-newdarkmode-700"
      )}
      {...omitKeys(props, ["darkest"])}
    />
  </div>
);

export default TextArea;
