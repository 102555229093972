/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */

import React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EmptyStep {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class PendingStep extends React.Component<{ value: string }, EmptyStep> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { value } = this.props;
    return (
      <>
        <div className="shadow-lg cursor-pointer dark:bg-newdarkmode-800 dark:border-1/2 dark:border-newdarkmode-600 dark:border-opacity-25 rounded-xl divide-y-1/2 divide-newdarkmode-600">
          <div
            className="flex items-center my-auto sm:justify-between sm:w-full"
            role="presentation"
          >
            <div className="relative flex py-1 pl-6 cursor-pointer xs:py-1 md:pl-6 lg:px-3  focus:outline-none">
              <p className="pr-4 my-auto text-base text-left text-red-500 xl:text-lg">
                {value}
              </p>
            </div>
          </div>
        </div>
        {}
      </>
    );
  }
}

export default PendingStep;
