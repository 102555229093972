/* eslint-disable arrow-body-style */
import React from "react";

import ChevronLeft from "src/Components/Icons/ChevronLeft";

const PageWrapper2 = ({ className, ...props }) => {
  return (
    <div
      className={`${className} flex justify-center w-11/12 h-auto min-h-page mx-auto overflow-hidden 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12`}
    >
      <div className="flex flex-col w-full h-auto mx-auto content-evenly">
        <button
          type="button"
          className="flex mt-3 mb-3 text-black cursor-pointer lg:mt-6 flex-nowrap dark:text-white dark:hover:text-gray-300 hover:text-gray-700"
          onClick={() => props.handlePageBack()}
        >
          <ChevronLeft className="w-8 h-8 my-auto font-extrabold" />
          <h1 className="my-auto ml-3 text-xl font-semibold text-left sm:text-2xl flex-grow-default">
            {props.title}
          </h1>
        </button>
        <div className="flex flex-col justify-center w-11/12 h-auto m-auto lg:w-full flex-grow-default">
          {props.children}
        </div>
        {props.button && (
          <div className="w-9/12 py-6 mx-auto text-xl font-semibold sm:pb-12 lg:pb-20">
            {props.button}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageWrapper2;
