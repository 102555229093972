import React from "react";
import { motion } from "framer-motion";
import Loader from "src/Components/Loaders/Loader";

const RoundedButton = ({ className = "", ...props }) => (
  <motion.button
    whileHover={{ scale: props.disabled ? 1 : 1.02 }}
    whileTap={{ scale: props.disabled ? 1 : 0.98 }}
    type="button"
    className={`${className} rounded-xl flex justify-center font-light px-3 ${
      props.lessMargin ? "py-1" : "py-1.5"
    } my-auto text-center flex-nowrap disabled:opacity-50 disabled:cursor-not-allowed`}
    onClick={() => (props.disabled ? null : props.onClick())}
    data-test-id={props["data-test-id"]}
    disabled={props.disabled}
  >
    <span className="flex justify-center w-full mx-auto my-auto flex-nowrap">
      {props.loading ? <Loader className="w-5 h-5 my-auto mr-3" /> : null}
      {props.children}
    </span>
  </motion.button>
);

export default RoundedButton;
