import React from "react";
import { classNames } from "src/Hooks/util";
import Copy from "../Icons/Copy";

const CopyButton = ({ className = "", ...props }) => (
  <div className={`${className} flex flex-col`}>
    {props.title && !props.hideTitle && (
      <h2 className="mt-3 mb-1 text-sm xs:text-base text-left ml-1.5 font-normal text-gray-900 sm:mt-4 dark:text-white">
        {props.title}
      </h2>
    )}
    <button
      type="button"
      className={classNames(
        "w-auto max-w-full py-1.5 my-auto text-gray-800 rounded-xl cursor-pointer hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-500 dark:border-darkmode-700 border-1"
      )}
      onClick={() => props.handleOnClick()}
      title={`Copy ${props.title}`}
    >
      <div className="flex justify-center px-3 flex-nowrap sm:px-4">
        <p className="w-auto my-auto text-base font-light truncate max-w-11/12">
          {props.children}
        </p>
        <Copy className="w-5 h-5 my-auto ml-3 text-white sm:ml-6" />
      </div>
    </button>
  </div>
);

export default CopyButton;
