/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const RoundedTabs = ({ ...props }) => {
  return (
    <div className="w-full p-1 bg-darkmode-800 rounded-xl">
      <div className="block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {props.tabs.map((tab) => (
            <button
              type="button"
              onClick={() => props.setSelected(tab)}
              key={tab.name}
              className={classNames(
                tab.name === props.selected.name
                  ? "bg-yellow-500 text-black"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-md mx-auto py-2 text-sm xs:text-base w-full font-medium"
              )}
              aria-current={
                tab.name === props.selected.name ? "page" : undefined
              }
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default RoundedTabs;
