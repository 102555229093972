import React from "react";

const ArrowUp = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    stroke="currentColor"
    strokeWidth="0.1"
    viewBox="0 0 16 16"
  >
    <path
      d="M6.99992 3.828L1.63592 9.192L0.221923 7.778L7.99992 6.79974e-07L15.7779 7.778L14.3639 9.192L8.99992 3.828L8.99992 16L6.99992 16L6.99992 3.828Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowUp;
