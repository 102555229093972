/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

interface Props {
  initialTheme?: string;
  children: React.ReactElement;
}

interface Theme {
  theme: string;
  setTheme?: React.Dispatch<React.SetStateAction<string>>;
}

const getInitialTheme = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("color-theme");
    if (typeof storedPrefs === "string") {
      return "dark";
    }
    const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
    if (userMedia.matches) {
      return "dark";
    }
  }
  return "dark";
};

export const ThemeContext = createContext<Theme>({ theme: getInitialTheme() });

const ThemeContextProvider: React.FC<Props> = ({ initialTheme, children }) => {
  const [theme, setTheme] = useState(getInitialTheme);
  const location = useLocation();

  const rawSetTheme = (rawTheme) => {
    const root = window.document.documentElement;
    const isDark = rawTheme === "dark";

    root.classList.remove(isDark ? "light" : "dark");
    root.classList.add(rawTheme);

    localStorage.setItem("color-theme", rawTheme);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  useEffect(() => {
    if (!TRACKING_ID) {
      console.error("Google Analytics Measurement ID is missing!");
      return;
    }

    ReactGA.initialize(TRACKING_ID);

    const trackPageView = (url) => {
      ReactGA.send({ hitType: "pageview", page: url });
    };

    trackPageView(location.pathname + location.search + location.hash);

    const removeListener = () => {
      window.addEventListener("hashchange", () => {
        trackPageView(location.pathname + location.search + location.hash);
      });
    };

    removeListener();

    /* eslint-disable consistent-return */
    return () => {
      window.removeEventListener("hashchange", removeListener);
    };
  }, [location]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
