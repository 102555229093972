/* eslint-disable arrow-body-style */
import React, { Fragment } from "react";
import { Dialog, Transition, DialogBackdrop } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import RoundedButton from "src/Components/Buttons/RoundedButton";

const ErrorModal = ({ ...props }) => {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={props.open}
        onClose={props.setOpen}
      >
        <div className="flex items-center justify-center min-h-screen text-center lg:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-10/12 px-4 pt-5 pb-6 overflow-hidden text-left text-black align-middle bg-gray-100 rounded-lg shadow-xl xl:max-w-4xl dark:bg-darkmode-900 dark:text-white transform transition-all">
              <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  className="m-3 text-gray-600 bg-gray-100 dark:bg-newdarkmode-700 rounded-md dark:text-yellow-500 hover:text-gray-500 dark:hover:text-yellow-400"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <div className="">
                <div className="mt-3 text-center">
                  <div className="m-auto">
                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-6 bg-red-100 rounded-full dark:bg-newdarkmode-700">
                      <ExclamationTriangleIcon
                        className="w-8 h-8 text-red-600 dark:text-yellow-500"
                        aria-hidden="true"
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="mx-4 my-auto text-lg font-medium text-black leading-6 dark:text-gray-300"
                    >
                      {props.title}
                    </Dialog.Title>
                  </div>
                  <div className="mt-4">
                    <p className="text-base text-black dark:text-gray-300">
                      {props.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-10/12 mx-auto mt-5 sm:w-5/12">
                <RoundedButton
                  className="inline-flex justify-center w-full px-4 mt-3 text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  Okay
                </RoundedButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ErrorModal;
