/* eslint-disable no-console */
/* global chrome */

import * as ext from "./ext";
import * as web from "./web";

const iext = Object.keys(ext);
const iweb = Object.keys(web);

if (iext.filter((f) => !iweb.includes(f)).length) {
  console.error("ext and web interfaces differ!");
}

export const isExtension = window.chrome && chrome.runtime && chrome.runtime.id;

export default isExtension ? ext : web;
