/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import { Disclosure, Transition } from "@headlessui/react";
import { Vault } from "bitmask-core/bitcoin";

import lib from "src/lib";
import { useNavigate } from "react-router-dom";

const ErrBounds = ({ ...props }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (props.error.message.includes("of 'location.state' as it is null.")) {
      navigate("/", { replace: true });
    }
  }, [props.error.message]);

  return (
    <div className="flex flex-col justify-center w-auto h-full max-w-4xl px-4 py-6 m-auto overflow-hidden text-left text-black align-middle sm:py-12 dark:text-white transform transition-all">
      <div className="">
        <div className="mt-3 text-center">
          <div className="m-auto">
            <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-6 bg-red-100 rounded-full dark:bg-newdarkmode-700">
              <ExclamationTriangleIcon
                className="w-8 h-8 text-red-600 dark:text-yellow-500"
                aria-hidden="true"
              />
            </div>
            <h3 className="mx-4 my-auto text-lg font-medium text-black xs:text-xl leading-6 dark:text-gray-300">
              An error has occurred
            </h3>
          </div>
          <div className="mt-4">
            <p className="text-base font-light text-left text-black xs:text-lg dark:text-gray-300">
              {props.error.message}
            </p>
            <Disclosure
              as="div"
              className="w-auto m-auto max-w-11/12 md:max-w-8/12 sm:px-6"
            >
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-center w-full py-6 m-auto text-lg font-normal text-center underline flex-nowrap ease-in-out">
                    {open ? "- Close error stack" : "+ View full error stack"}
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel
                      as="p"
                      className="flex justify-center w-full pb-6 m-auto text-sm font-light text-left break-all sm:text-base sm:break-normal"
                    >
                      {props.error.stack}
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </div>
      <div className="w-10/12 mx-auto mt-5 sm:w-5/12">
        <RoundedButton
          className="inline-flex justify-center w-full px-4 mt-3 text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm"
          onClick={async () => {
            // TODO: remove lib storage for vault and get that and hash properly
            const vault = await lib.storageGet<Vault>(["vault"]);
            const wallet = "wallet_01";
            props.setError({ hasError: false });
            if (vault) {
              navigate("/wallet", {
                state: { wallet, vault },
                replace: true,
              });
            } else {
              navigate("/", { replace: true });
            }
          }}
        >
          Reload bitmask
        </RoundedButton>
      </div>
    </div>
  );
};

class ErrorBoundary extends React.Component<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  { hasError: boolean; error: { message: string; stack: string } }
> {
  // eslint-disable-next-line react/state-in-constructor
  state = { hasError: false, error: { message: "", stack: "" } };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(err) {
    this.setState({ error: { message: err.message, stack: err.stack } });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrBounds
          error={this.state.error}
          setError={() => this.setState({ hasError: false })}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
