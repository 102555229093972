/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

const LineChart = ({ ...props }) => {
  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const colorMap = {
    0: "#e41a1c",
    1: "#377eb8",
    2: "#4daf4a",
    3: "#984ea3",
    4: "#ff7f00",
    5: "#ffff33",
    6: "#a65628",
    7: "#f781bf",
    8: "#fa9fb5",
    9: "#e0ecf4",
    10: "#edf8b1",
    11: "#bcbddc",
  };

  const data = {
    labels: props.labels,
    datasets: props.data.map((d, i) => ({
      fill: "origin",
      label: d.title,
      data: d.data,
      borderWidth: 2,
      pointRadius: 2.4,
      borderColor: d.plotColor ? d.plotColor : colorMap[i],
      backgroundColor: hexToRgba(
        d.bgColor ? d.bgColor : colorMap[i],
        d.bgOpacity ? d.bgOpacity : 0.5
      ),
      pointBackgroundColor: d.plotColor ? d.plotColor : colorMap[i],
      pointBorderColor: d.plotColor ? d.plotColor : colorMap[i],
    })),
  };

  return (
    <div style={{ minHeight: "27em" }}>
      <Line
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          spanGaps: true,
          animation: props.animation,
          scales: {
            y: {
              stacked: true,
              grid: {
                color: "#52606D",
              },
              ticks: {
                color: "white",
                fontSize: 12,
              },
            },
            x: {
              grid: {
                color: "#52606D",
              },
              ticks: {
                color: "white",
                fontSize: 12,
              },
            },
          },
          layout: {
            padding: {
              left: 0,
              right: 20,
              top: 0,
              bottom: 0,
            },
          },
          plugins: {
            legend: {
              display: true,
              labels: {
                color: "white",
              },
            },
            title: {
              display: true,
              text: props.title,
              color: "white",
              font: {
                size: 16,
                weight: "bold",
              },
            },
          },
        }}
      />
    </div>
  );
};

export default LineChart;
