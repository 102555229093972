/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */

import * as backend from "bitmask-core";
import { FundVaultDetails, Vault, WalletData } from "bitmask-core/bitcoin";
import {
  ContractResponse,
  IssueRequest,
  IssueResponse,
  MediaData,
  RgbInvoiceRequest,
  RgbInvoiceResponse,
  RgbTransferRequest,
  RgbTransferResponse,
} from "bitmask-core/rgb";
import { Network } from "bitmask-core/constants";
import { getFeeRate } from "src/Hooks/util";

import React from "react";

import Step from "./Step";
import ExecuteStep from "./ExecuteStep";

interface RgbState {
  steps: ExecuteStep[];

  alice: Vault | undefined;
  aliceSk: string;
  aliceUtxo: string;
  aliceKeys: string[];

  bob: Vault | undefined;
  bobSk: string;
  bobBtcUtxo: string;
  bobRgbFun1Utxo: string;
  bobFun1Invoice: string;
  bobFun2Invoice: string;

  ifaceFun1: string;
  contractFun1Id: string;
  contractFun1: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class RgbFundVault extends React.Component<{ value: string }, RgbState> {
  constructor(props) {
    super(props);

    this.state = {
      steps: [],

      alice: undefined,
      aliceSk: "",
      aliceUtxo: "",
      aliceKeys: [],

      bob: undefined,
      bobSk: "",
      bobBtcUtxo: "",
      bobRgbFun1Utxo: "",
      bobFun1Invoice: "",
      bobFun2Invoice: "",

      ifaceFun1: "RGB20",
      contractFun1Id: "",
      contractFun1: "",
    };
  }

  async handleClick() {
    // 0.
    await this.useRegtest();

    // 1.
    await this.createAliceBobWallet();
  }

  // 0.
  // eslint-disable-next-line class-methods-use-this
  async useRegtest() {
    await backend.switch_network(Network.regtest.toString());
    window.localStorage.setItem("network", Network.regtest.toString());
  }

  // 1.
  async createAliceBobWallet() {
    const { ifaceFun1 } = this.state;
    const bitcoin = "bitcoin";
    const server = `${process.env.LOCAL_BITMASK_ENDPOINT}`;

    const usecase = new ExecuteStep("create alice and bob wallets");

    const hash = backend.bitcoin.hashPassword("");

    const aliceWallet = await backend.bitcoin.newWallet(hash, "");
    const alice = await backend.bitcoin.decryptWallet(hash, aliceWallet);
    usecase.ok("Alice wallet created");

    const bobWallet = await backend.bitcoin.newWallet(hash, "");
    const bob = await backend.bitcoin.decryptWallet(hash, bobWallet);
    usecase.ok("Bob wallet created");

    if (alice && bob) {
      const bobSk = bob.private.nostrPrv;
      const aliceSk = alice.private.nostrPrv;
      const aliceKeys = [
        alice.private.rgbDescriptorXprv,
        alice.private.btcDescriptorXprv,
      ];

      await backend.rgb.createWatcher(aliceSk, {
        name: "default",
        xpub: alice.public.btcDescriptorXpub,
        force: false,
      });
      usecase.ok(`Alice watcher created`);

      await backend.rgb.createWatcher(bobSk, {
        name: "default",
        xpub: bob.public.btcDescriptorXpub,
        force: false,
      });
      usecase.ok(`Bob watcher created`);

      const aliceAddr = (await backend.rgb.nextAddress(aliceSk, bitcoin))
        .address;
      usecase.ok(`Alice address (BTC): ${aliceAddr}`);

      if (aliceAddr) await fetch(`${server}/regtest/send/${aliceAddr}/1`);

      const rgbAddress = await backend.bitcoin.getNewAddress(
        alice.public.rgbDescriptorXpub
      );

      usecase.ok(`Alice address [BDK] (RGB): ${rgbAddress}`);

      // Sync Alice Wallet (BDK) - Private Keys
      usecase.ok(`Alice Sync RGB Wallet (BDK)`);
      await backend.bitcoin.getWalletData(
        alice.private.btcDescriptorXprv,
        alice.private.btcChangeDescriptorXprv
      );

      const feeRate = await getFeeRate();
      const aliceFundVault: FundVaultDetails = await backend.bitcoin.fundVault(
        alice.private.btcDescriptorXprv,
        alice.private.btcChangeDescriptorXprv,
        rgbAddress,
        true,
        feeRate
      );

      const aliceUtxo = aliceFundVault.rgbOutput || "";
      usecase.ok(`Alice UTXO [BDK] (RGB): ${aliceUtxo}`);

      const bobAddr = (await backend.rgb.nextAddress(bobSk, bitcoin)).address;
      usecase.ok(`Bob address (BTC): ${bobAddr}`);

      if (bobAddr) await fetch(`${server}/regtest/send/${bobAddr}/1`);

      const bobRgbAddr = await backend.bitcoin.getNewAddress(
        bob.public.rgbDescriptorXpub
      );
      usecase.ok(`Bob address [BDK] (RGB): ${bobRgbAddr}`);

      // Sync Bob Wallet (BDK) - Private Keys
      usecase.ok(`Bob Sync RGB Wallet (BDK)`);
      await backend.bitcoin.getWalletData(
        bob.private.btcDescriptorXprv,
        bob.private.btcChangeDescriptorXprv
      );

      const bobFundVault: FundVaultDetails = await backend.bitcoin.fundVault(
        bob.private.btcDescriptorXprv,
        bob.private.btcChangeDescriptorXprv,
        bobRgbAddr,
        true,
        feeRate
      );

      const bobRgbFun1Utxo = bobFundVault.rgbOutput || "";
      usecase.ok(`Bob UTXO [BDK] (RGB): ${bobRgbFun1Utxo}`);

      const bobBtcUtxo =
        (await backend.rgb.nextUtxo(bobSk, bitcoin))?.utxo || "";

      await fetch(`${server}/regtest/block`);
      this.setState(
        {
          alice,
          aliceSk,
          aliceUtxo,
          aliceKeys,
          bob,
          bobSk,
          bobBtcUtxo,
          bobRgbFun1Utxo,
          steps: [usecase],
        },
        async () => {
          // 2.
          await this.createAliceContract();
        }
      );
    }
  }

  // 2.
  async createAliceContract() {
    const { aliceSk, aliceUtxo, ifaceFun1: iface, steps } = this.state;

    const usecase = new ExecuteStep("create alice fungible contract");

    const issueReq: IssueRequest = {
      iface,
      ticker: "DIBA",
      name: "DIBA",
      description: "DIBA",
      supply: "5",
      precision: 0,
      seal: `tapret1st:${aliceUtxo}`,
      chain: "bitcoin",
    };

    const {
      iface: contractIface,
      contractId,
      contract: { armored: contract },
      balance,
    }: IssueResponse = JSON.parse(
      await backend.issue_contract_proxy(aliceSk, issueReq)
    ).data;

    usecase.ok(
      `Alice contract: [${contractIface}] ${contractId} (${balance.value})`
    );

    steps.push(usecase);
    this.setState(
      { contractFun1Id: contractId, contractFun1: contract, steps },
      async () => {
        // 3.
        await this.createBobInvoice1();
      }
    );
  }

  // 3.
  async createBobInvoice1() {
    const {
      bobSk,
      bobRgbFun1Utxo: bobRgbUtxo,
      contractFun1Id: contractId,
      contractFun1: contract,
      ifaceFun1: iface,
      steps,
    } = this.state;
    const usecase = new ExecuteStep("create bob fungible invoice 1");

    await backend.import_contract(bobSk, contract);
    usecase.ok(`Bob imported contract: ${contractId}`);

    const invoiceReq: RgbInvoiceRequest = {
      contractId,
      iface,
      seal: { utxo: `${bobRgbUtxo}` },
      amount: { value: "1" },
    };

    const { invoice }: RgbInvoiceResponse = JSON.parse(
      await backend.create_rgb_invoice(bobSk, invoiceReq)
    ).data;

    usecase.ok(`Bob invoice: ${invoice}`);

    steps.push(usecase);
    this.setState({ steps, bobFun1Invoice: invoice }, async () => {
      // 4.
      await this.createBobInvoice2();
    });
  }

  // 4.
  async createBobInvoice2() {
    const {
      bobSk,
      bobRgbFun1Utxo: bobRgbUtxo,
      contractFun1Id: contractId,
      contractFun1: contract,
      ifaceFun1: iface,
      steps,
    } = this.state;
    const usecase = new ExecuteStep("create bob fungible invoice 2");

    await backend.import_contract(bobSk, contract);
    usecase.ok(`Bob imported contract: ${contractId}`);

    const invoiceReq: RgbInvoiceRequest = {
      contractId,
      iface,
      seal: { utxo: `${bobRgbUtxo}` },
      amount: { value: "1" },
    };

    const { invoice }: RgbInvoiceResponse = JSON.parse(
      await backend.create_rgb_invoice(bobSk, invoiceReq)
    ).data;

    usecase.ok(`Bob invoice: ${invoice}`);

    steps.push(usecase);
    this.setState({ steps, bobFun2Invoice: invoice }, async () => {
      // 5.
      await this.createAliceFunTransfer1();
    });
  }

  // 5.
  async createAliceFunTransfer1() {
    const server = `${process.env.LOCAL_BITMASK_ENDPOINT}`;
    const {
      aliceSk,
      aliceKeys,
      bobFun1Invoice: invoice,
      contractFun1Id: contractId,
      ifaceFun1: iface,
      steps,
    } = this.state;
    const usecase = new ExecuteStep(
      "create alice fungible transfer (invoice 1)"
    );

    const transferReq: RgbTransferRequest = {
      contractId,
      iface,
      invoice,
      chain: "bitcoin",
      chainFee: { value: BigInt(1000) },
      bitcoinChanges: [],
    };

    const { consigId }: RgbTransferResponse = JSON.parse(
      await backend.create_and_publish_rgb_transfer(
        aliceSk,
        transferReq,
        aliceKeys
      )
    ).data;

    usecase.ok(`Alice transfer: ${consigId}`);
    await fetch(`${server}/regtest/block`);

    steps.push(usecase);
    this.setState({ steps }, async () => {
      // 6.
      await this.updateAliceUtxos();
    });
  }

  // 6.
  async updateAliceUtxos() {
    const { aliceSk, alice, ifaceFun1: iface, steps } = this.state;
    const usecase = new ExecuteStep("update alice utxos");

    await backend.verify_transfers(aliceSk);
    usecase.ok(`Alice update transfers`);

    const aliceUtxo = (await backend.rgb.nextUtxo(aliceSk, iface))?.utxo || "";
    usecase.ok(`Alice UTXO [WATCHER] (${iface}): ${aliceUtxo}`);

    const alicePk = alice?.private.btcDescriptorXprv || "";
    const aliceVault = await backend.bitcoin.getWalletData(alicePk);
    usecase.ok(`Alice UTXO [BDK] (${iface}): ${aliceVault.utxos[0]}`);

    steps.push(usecase);
    this.setState({ steps, aliceUtxo }, async () => {
      // 7.
      await this.createAliceFungibleTransfer2();
    });
  }

  // 7.
  async createAliceFungibleTransfer2() {
    const server = `${process.env.LOCAL_BITMASK_ENDPOINT}`;
    const {
      aliceSk,
      aliceKeys,
      bobFun2Invoice: invoice,
      contractFun1Id: contractId,
      ifaceFun1: iface,
      steps,
    } = this.state;
    const usecase = new ExecuteStep(
      "create alice fungible transfer (invoice 2)"
    );

    const transferReq: RgbTransferRequest = {
      contractId,
      iface,
      invoice,
      chain: "bitcoin",
      chainFee: { value: BigInt(1000) },
      bitcoinChanges: [],
    };

    const { consigId }: RgbTransferResponse = JSON.parse(
      await backend.create_and_publish_rgb_transfer(
        aliceSk,
        transferReq,
        aliceKeys
      )
    ).data;

    usecase.ok(`Alice transfer: ${consigId}`);
    await fetch(`${server}/regtest/block`);

    steps.push(usecase);
    this.setState({ steps }, async () => {
      // 8.
      await this.aliceContracts();
    });
  }

  // 8.
  async aliceContracts() {
    const { aliceSk, contractFun1Id, steps } = this.state;
    const usecase = new ExecuteStep("alice contracts state verification");

    await backend.verify_transfers(aliceSk);
    usecase.ok(`Alice update transfers`);

    const {
      iface: iface20,
      balance: balance20,
      allocations: allocations20,
    }: ContractResponse = JSON.parse(
      await backend.get_contract(aliceSk, contractFun1Id)
    );

    usecase.ok(
      `Alice contract: [${iface20}]  ${contractFun1Id} / value: ${JSON.stringify(
        balance20.value
      )}`
    );

    const filterAllocations = allocations20.filter(
      (x) => x.isMine && !x.isSpent
    );
    usecase.ok(`Alice allocations: (${JSON.stringify(filterAllocations)})`);

    steps.push(usecase);
    this.setState({ steps }, async () => {
      // 9.
      await this.bobContracts();
    });
  }

  // 9.
  async bobContracts() {
    const { bobSk, contractFun1Id, steps } = this.state;
    const usecase = new ExecuteStep("bob contracts state verification");

    await backend.verify_transfers(bobSk);
    usecase.ok(`Bob update transfers`);

    const {
      iface: iface20,
      balance: balance20,
      allocations: allocations20,
    }: ContractResponse = JSON.parse(
      await backend.get_contract(bobSk, contractFun1Id)
    );

    usecase.ok(
      `Bob contract: [${iface20}]  ${contractFun1Id} / value: ${JSON.stringify(
        balance20.value
      )}`
    );

    const filterAllocations = allocations20.filter(
      (x) => x.isMine && !x.isSpent
    );
    usecase.ok(`Bob allocations: (${JSON.stringify(filterAllocations)})`);

    steps.push(usecase);
    this.setState({ steps });
  }

  render() {
    const { steps } = this.state;
    const { value } = this.props;
    return (
      <>
        <div className="shadow-lg cursor-pointer dark:bg-newdarkmode-800 dark:border-1/2 dark:border-newdarkmode-600 dark:border-opacity-25 rounded-xl divide-y-1/2 divide-newdarkmode-600">
          <div
            className="flex items-center my-auto sm:justify-between sm:w-full"
            onClick={async () => this.handleClick()}
            onKeyDown={async () => this.handleClick()}
            role="presentation"
          >
            <div className="relative flex py-2 pl-6 cursor-pointer xs:py-4 md:pl-9 lg:px-6 focus:outline-none">
              <p className="pr-4 my-auto text-base text-left text-yellow-500 xl:text-lg">
                {value}
              </p>
            </div>
          </div>
        </div>
        {steps.map((step: ExecuteStep, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Step key={index} usecase={step.usecase} result={step.result} />
        ))}
      </>
    );
  }
}

export default RgbFundVault;
