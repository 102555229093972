/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from "react";

interface MainNav {
  mainNav: boolean;
  setMainNav?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MainNavContext = createContext<MainNav>({ mainNav: false });

const MainNavContextProvider = ({ ...props }) => {
  const [mainNav, setMainNav] = useState(false);

  return (
    <MainNavContext.Provider value={{ mainNav, setMainNav }}>
      {props.children}
    </MainNavContext.Provider>
  );
};

export default MainNavContextProvider;
