/* eslint-disable arrow-body-style */
import React from "react";

import ChevronLeft from "src/Components/Icons/ChevronLeft";
import RoundedButton from "src/Components/Buttons/RoundedButton";

const PageWrapper = ({ ...props }) => {
  return (
    <div className="flex justify-center w-11/12 h-auto mx-auto overflow-hidden min-h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
      <div className="flex flex-col w-full content-evenly">
        <button
          type="button"
          className="flex my-auto mt-3 mb-6 text-black cursor-pointer lg:mt-6 gap-3 flex-nowrap dark:text-white dark:hover:text-gray-300 hover:text-gray-700"
          onClick={() => props.handlePageBack()}
        >
          <ChevronLeft className="w-8 h-8 my-auto font-bold" />
          <h1 className="my-auto text-xl font-medium text-left sm:text-2xl flex-grow-default">
            {props.title}
          </h1>
        </button>
        <div className="flex flex-col justify-center w-11/12 h-auto m-auto">
          {props.children}
        </div>
        <div className="w-11/12 py-6 mx-auto sm:pb-12 lg:pb-20 grid grid-cols-2">
          <RoundedButton
            className="mr-2 text-base text-black dark:bg-darkmode-700 dark:text-white disabled:opacity-50"
            onClick={() => props.handleCancel()}
            disabled={props.loading}
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            className="ml-2 text-base bg-yellow-500 text-newdarkmode-900 disabled:opacity-50"
            onClick={() => props.handleSubmit()}
            loading={props.loading}
            disabled={props.loading}
          >
            {props.confirmation ? "Confirm" : "Next"}
          </RoundedButton>
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
