import React, { useState } from "react";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import Circles from "src/Components/Steps/Circles";
import TextArea from "src/Components/Inputs/TextArea";
import useWindowSize from "src/Hooks/useWindowSize";
import { classNames } from "src/Hooks/util";
import Copy from "src/Components/Icons/Copy";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";

const Card2 = ({ handleChange, ...props }) => {
  const size = useWindowSize();
  const [inputValue, setInputValue] = useState("");
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [confirmSeedSaved, setConfirmSeedSaved] = useState(false);
  const [error, setError] = useState({ status: false, message: "" });
  const phrases = props.mnemonic;

  const sameMembers = (arr1, arr2) => {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    return (
      arr1.every((item) => set2.has(item)) &&
      arr2.every((item) => set1.has(item))
    );
  };

  const handleSubmit = () => {
    if (
      sameMembers(
        inputValue
          .trim()
          .toLowerCase()
          .split(/ |\n|,/g),
        phrases
      ) &&
      confirmSeedSaved
    ) {
      navigator.clipboard.writeText(
        "Your BitMask Wallet seed phrase has been overwritten by this message for security purposes........"
      );
      setError({ ...error, status: false });
      props.setSteps([
        { name: "Set Password", href: "#", status: "complete" },
        { name: "Create Username", href: "#", status: "complete" },
        { name: "Secure Wallet", href: "#", status: "complete" },
      ]);
      handleChange("complete");
    } else if (confirmSeedSaved) {
      setError({
        status: true,
        message: "Incorrect Secret Phrase. Please try again.",
      });
    } else {
      setError({
        status: true,
        message:
          "Confirm you have saved your seed phrase in a secure, private location.",
      });
    }
  };

  return (
    <div className="w-full h-auto max-w-2xl m-auto text-center text-gray-900 xs:pb-6 sm:pb-12 dark:text-gray-300">
      <Circles
        steps={props.steps}
        name="Confirm seed phrase"
        className="w-full pl-6"
      />
      <h1 className="m-auto mt-5 mb-3 text-xl font-medium leading-tight text-center sm:my-6 xs:text-2xl flex-grow-default">
        Seed phrase confirmation
      </h1>
      <p className="w-full m-auto my-3 text-base font-thin text-left text-gray-500 sm:text-lg flex-grow-default leading-7 sm:leading-8">
        Enter your seed phrase with one space in between all words in lower
        case.
      </p>
      <div className="m-auto grid grid-cols-3 md:grid-cols-4 py-1.5">
        {phrases.map((phrase, i) => (
          <div
            key={phrase}
            className={classNames(
              phrase === inputValue.trim().toLowerCase().split(" ")[i]
                ? "bg-yellow-500 bg-opacity-25"
                : "text-white",
              inputValue.trim().toLowerCase().split(" ")[i] &&
                !inputValue.trim().toLowerCase().split(" ")[i + 1] &&
                phrase !== inputValue.trim().toLowerCase().split(" ")[i]
                ? "bg-gray-500 bg-opacity-50 text-white"
                : "",
              inputValue.trim().toLowerCase().split(" ")[i] &&
                inputValue.trim().toLowerCase().split(" ")[i + 1] &&
                phrase !== inputValue.trim().toLowerCase().split(" ")[i]
                ? "bg-red-600 bg-opacity-25 text-white"
                : "",
              !inputValue.trim().toLowerCase().split(" ")[i]
                ? "bg-darkmode-700"
                : "",
              "text-xs xs:text-base font-light rounded-lg p-1.5 m-1 md:m-1.5 text-white text-left"
            )}
          >
            <span className="text-gray-500">{i + 1}. </span>
            {phrase}
          </div>
        ))}
      </div>
      <button
        type="button"
        title="Copy wallet address"
        onClick={() => {
          navigator.clipboard.writeText(phrases.join(" "));
          setOpenCopyModal(true);
        }}
        className="rounded-lg shadow-sm mt-1 px-2 py-0.5 sm:py-1 flex bg-gray-300 dark:bg-newdarkmode-800 dark:bg-opacity-75 font-thin cursor-pointer text-sm text-center w-full m-auto flex-nowrap text-gray-900 hover:bg-gray-300 dark:text-gray-500 dark:hover:text-gray-400"
      >
        <p className="flex justify-center w-auto m-auto text-center truncate">
          Copy mnemonic seed phrase
        </p>
        <Copy className="flex flex-none w-6 h-6" />
      </button>
      <TextArea
        className="w-full p-2 mt-2"
        rows={2}
        placeholder="Enter seed phrase"
        label="Confirm seed phrase"
        onChange={(e) => {
          setError({ ...error, status: false });
          setInputValue(e.target.value);
        }}
        onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : null)}
        darkest={size.width >= 500}
      />
      {error.status && (
        <h3 className="px-6 text-sm sm:text-base text-red-500 text-left font-light md:text-center sm:pb-1.5 w-full m-auto">
          {error.message}
        </h3>
      )}
      <div className="flex m-auto mb-5 xs:mt-3 flex-nowrap">
        <input
          type="checkbox"
          className="w-5 h-5 my-auto mr-3"
          onChange={() => {
            setError({ ...error, status: false });
            setConfirmSeedSaved(!confirmSeedSaved);
          }}
          onKeyDown={(e) =>
            e.key === "Enter"
              ? () => {
                  setError({ ...error, status: false });
                  setConfirmSeedSaved(!confirmSeedSaved);
                }
              : null
          }
        />
        <p className="my-auto text-sm font-thin text-left text-gray-900 sm:text-base dark:text-gray-500">
          I&#39;ve saved my seed phrase in a secure, private place.
        </p>
      </div>
      <div className="my-6">
        <RoundedButton
          className="w-full py-1 mx-auto mt-8 text-base font-medium text-gray-900 bg-yellow-500 sm:text-lg"
          onClick={() => handleSubmit()}
        >
          Confirm
        </RoundedButton>
      </div>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
    </div>
  );
};

export default Card2;
