/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useState } from "react";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import Circles from "src/Components/Steps/Circles";
import TextField from "src/Components/Inputs/TextField";
import useWindowSize from "src/Hooks/useWindowSize";
import { isExtension } from "src/lib";

const CreateUsername = ({ handleChange, ...props }) => {
  const size = useWindowSize();
  const [error, setError] = useState("");
  const { username, vault } = props;
  const [loading, setLoading] = useState(false);

  const submitUsername = () => {
    props.setSteps([
      { name: "Set Password", href: "#", status: "complete" },
      { name: "Create Username", href: "#", status: "complete" },
      { name: "Secure Wallet", href: "#", status: "current" },
    ]);
    handleChange("secureWallet");
  };

  const handleSubmit = async () => {
    const { xpubkh } = vault.public;
    if (username && xpubkh) {
      setLoading(true);
      const checkUsernameIsUnique = await fetch(
        `${
          isExtension ? "https://beta.bitmask.app" : ""
        }/api/checkusername?lookup=${username.toLowerCase()}`
      );
      const checkedUsername = await checkUsernameIsUnique.json();

      if (checkedUsername.notFound === true) {
        const createUsername = await fetch(
          `${isExtension ? "https://beta.bitmask.app" : ""}/api/username`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: username.toLowerCase(),
              xpubkh,
              xprvkh: vault.private.xprvkh,
            }),
          }
        );
        const creationSuccess = await createUsername.json();
        if (creationSuccess.success === true) {
          window.localStorage.setItem(
            "username",
            `${username.toLowerCase()}@bitmask.app`
          );
          submitUsername();
        } else if (creationSuccess.reason) {
          setLoading(false);
          setError(creationSuccess.reason);
        } else {
          setLoading(false);
          setError("Error creating username");
        }
      } else if (checkedUsername.success) {
        setLoading(false);
        setError("Username already in use");
      } else {
        setLoading(false);
        setError(checkedUsername.error);
      }
    } else {
      setError(
        !username
          ? "Username field cannot be left blank"
          : "Public key hash not found"
      );
    }
  };

  return (
    <div className="w-full h-auto max-w-2xl pb-10 m-auto mx-3 text-center xs:mx-6 sm:m-auto">
      <Circles
        steps={props.steps}
        name="Create username"
        className="w-full pl-12"
      />
      <h1 className="text-2xl font-medium text-center text-gray-900 xs:text-3xl dark:text-gray-300 flex-grow-default">
        Create Username
      </h1>
      <p className="w-full m-auto my-3 text-base font-thin text-left text-gray-500 sm:text-lg flex-grow-default leading-7 sm:leading-8">
        Your username allows you to create a handle (ex. username@bitmask.app)
        for use with Lightning Network and Nostr.
      </p>
      <TextField
        type="text"
        className="w-full p-3 mt-2"
        placeholder="Create username"
        label="Create username"
        onChange={(e) => {
          setError("");
          props.setUsername(e.target.value);
        }}
        darkest={size.width >= 500}
      />
      {error !== "" && (
        <div className="m-auto">
          <p className="text-sm font-semibold text-left text-red-600 flex-grow-default">
            {error}
          </p>
        </div>
      )}
      <RoundedButton
        className="w-full py-1 mx-auto mt-8 text-base font-medium text-gray-900 bg-yellow-500 sm:text-lg"
        onClick={() => handleSubmit()}
        loading={loading}
      >
        Continue
      </RoundedButton>
    </div>
  );
};

export default CreateUsername;
