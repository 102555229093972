/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import matter from "gray-matter";
import Loader from "src/Components/Loaders/Loader";
import { isExtension } from "src/lib";
import { classNames } from "src/Hooks/util";

const components = {
  img: ({ node, ...props }) => <img className="w-full lg:my-6" {...props} />,
  a: ({ node, ...props }) => (
    <a
      href={props.href}
      className="font-normal underline lg:font-light"
      {...props}
    />
  ),
  h1: ({ node, ...props }) => (
    <h1 className="text-2xl font-light font-integral" {...props} />
  ),
  h2: ({ node, ...props }) => (
    <h2 className="text-2xl font-light font-integral" {...props} />
  ),
  ol: ({ node, ...props }) => (
    <ol className="list-decimal list-inside" {...props} />
  ),
  li: ({ node, ...props }) => <li className="p-0 my-0" {...props} />,
  p: ({ node, ...props }) => <p className="p-0 m-0" {...props} />,
};

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(null);

  useEffect(() => {
    import(`src/markdown/privacypolicy.md`).then((res) => {
      fetch(res.default)
        .then((response) => response.text())
        .then((response) => {
          setPrivacyPolicy(matter(response));
        })
        .catch((err) => console.error(err));
    });
  }, []);

  if (!privacyPolicy?.data?.title) {
    return (
      <div
        className={classNames(
          isExtension ? "min-w-[400px]" : "min-w-80",
          "flex justify-center w-full h-full m-auto text-gray-900 dark:text-gray-300 bg-gray-100 dark:bg-darkmode-900 dark:bg-newdarkmode-700"
        )}
      >
        <Loader className="w-20 h-20 m-auto" />
      </div>
    );
  }

  return (
    <div className="px-3 py-6 font-light dark:bg-darkmode-900 sm:px-6 sm:py-12">
      <h1 className="pb-3 text-xl font-medium xs:text-3xl dark:text-white">
        BitMask Wallet Privacy Policy
      </h1>
      <div className="pt-6 pb-6 text-sm text-left text-black whitespace-pre-line sm:text-base leading-9 sm:pb-12 dark:text-white">
        <ReactMarkdown linkTarget="_blank" components={components}>
          {privacyPolicy.content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
