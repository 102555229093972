import { WalletData } from "bitmask-core/bitcoin";

import lib from "src/lib";
import { Asset } from "src/types";

export const updatingAssets = async (
  walletData: WalletData,
  assetData: Asset
) => {
  if (!walletData.name) {
    console.error("wallet name missing in updateAssets");
  }
  const key = `${walletData.name}.assets`;
  const result = await lib.storageGet<Asset[]>([key]);
  let futureAssets: Asset[] = [];
  if (result[key]) futureAssets = result[key];
  const index = futureAssets.findIndex(
    (p) => p.contractId === assetData.contractId
  );
  if (index === -1) {
    futureAssets.push(assetData);
  } else {
    futureAssets[index] = assetData;
  }
  await lib.storageSet({ [key]: futureAssets });
  return futureAssets;
};

export default updatingAssets;
