/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import SecondaryNav from "src/Components/Navbar/SecondaryNav";
import Copy from "src/Components/Icons/Copy";
import Loader from "src/Components/Loaders/Loader";
import Pagination from "src/Components/Navigation/Pagination";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import { isExtension } from "src/lib";
import { classNames, getLargeNumber } from "src/Hooks/util";
import useWindowSize from "src/Hooks/useWindowSize";
import Search from "src/Components/Inputs/Search";

const Registry = () => {
  // eslint-disable-next-line no-unused-vars
  const size = useWindowSize();
  const [height, setHeight] = useState(0);
  const [page, setPage] = useState(1);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [assets, setAssets] = useState<any[]>([]);
  const [loggedIn, setLoggedIn] = useState(true);
  const [mainnet, setMainnet] = useState(true);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [search, setSearch] = useState("");

  const isPopup = window.location.href.split("?").pop()?.includes("popup");

  const handleRedirectToFullscreenTab = () => {
    window.close();
    const win = window.open(
      `${window.location.href.split("?")[0]}/#/registry`,
      "_blank"
    );
    win?.focus();
  };

  useEffect(() => {
    if (
      isPopup &&
      (window.location.href.split("/").pop() === "index.html" ||
        window.location.href.split("/").pop() === "registry")
    ) {
      handleRedirectToFullscreenTab();
    }
  }, []);

  const getAssets = async (currentPage) => {
    setLoading(true);
    const assetSet = await fetch(
      `${isExtension ? "https://beta.bitmask.app" : ""}/api/paginate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: currentPage,
          size: 10,
          searchKeyword: search,
          network: mainnet ? "bitcoin" : "signet",
        }),
      }
    );
    const resp = await assetSet.json();
    if (resp.data) setLoading(false);
    setAssets(resp.data);
    setAmount(resp.amount);
  };

  useEffect(() => {
    const loadAssets = async () => {
      setLoggedIn(false);
      if (localStorage.getItem(`${mainnet ? "bitcoin" : "signet"}Descriptor`)) {
        setLoggedIn(true);
        getAssets(page);
      } else {
        getAssets(page);
      }
    };
    loadAssets();
  }, [page, mainnet]);

  useEffect(() => {
    setPage(1);
    const loadAssets = async () => {
      setLoggedIn(false);
      if (localStorage.getItem(`${mainnet ? "bitcoin" : "signet"}Descriptor`)) {
        setLoggedIn(true);
        getAssets(1);
      } else {
        getAssets(1);
      }
    };
    loadAssets();
  }, [search, mainnet]);

  useLayoutEffect(() => {
    setHeight(size.height - 56);
  }, [size]);

  const randColors = [
    "bg-purple-500",
    "bg-green-500",
    "bg-blue-500",
    "bg-yellow-500",
    "bg-pink-500",
  ];

  return (
    <div className="sticky top-0 z-50 flex flex-col justify-center h-full min-h-full overflow-auto bg-gray-200 dark:bg-darkmode-900 dark:darkscrollbar">
      <SecondaryNav
        className="text-black bg-gray-200 dark:text-white dark:bg-darkmode-900"
        menuItems={[
          loggedIn && { name: "Wallet", route: "/wallet" },
          { name: "Metrics", route: "/metrics" },
          { name: "FAQ", route: "/faq" },
          { name: "GitHub", link: "https://github.com/diba-io/bitmask-core/" },
        ]}
      />
      <div
        style={{
          height,
          minHeight: height,
        }}
        className="flex flex-col w-full h-auto min-h-screen m-auto mx-auto overflow-y-auto dark:darkscrollbar"
      >
        <div className="w-11/12 h-auto pt-3 pb-6 mx-auto 4xl:w-8/12 3xl:w-9/12 2xl:w-10/12 xl:w-10/12 lg:w-11/12">
          <div className="flex flex-col items-center">
            <div className="flex-auto m-auto">
              <h1 className="text-xl font-semibold text-black sm:text-2xl lg:text-3xl dark:text-white">
                BitMask Asset Registry
              </h1>
            </div>
            <p className="flex my-3 text-sm font-thin text-gray-900 md:my-6 flex-grow-default sm:text-base lg:text-lg dark:text-gray-500">
              Data on all publicly listed {mainnet ? "mainnet" : "signet"} RGB
              assets created on BitMask
            </p>
            <div className="flex flex-row w-full gap-3">
              <Menu
                as="div"
                className="relative inline-block text-left m-auto ml-0"
              >
                <div>
                  <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-lg bg-darkmode-700 px-3 py-2 text-sm font-semibold dark:text-gray-300 shadow-sm hover:bg-darkmode-600">
                    Network
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="-mr-1 h-5 w-5 text-gray-400"
                    />
                  </MenuButton>
                </div>

                <MenuItems
                  transition
                  className="absolute left-0 z-10 mt-2 w-56 origin-top-left divide-y divide-darkmode-400 rounded-md bg-darkmode-800 shadow-lg ring-1 ring-gray-800 ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1 text-left">
                    <MenuItem>
                      <button
                        type="button"
                        onClick={() => setMainnet(true)}
                        className="block text-left px-4 py-2 text-sm dark:text-gray-300 data-[focus]:bg-darkmode-700 dark:hover:bg-darkmode-600 dark:hover:text-gray-500 w-full"
                      >
                        bitcoin
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        type="button"
                        onClick={() => setMainnet(false)}
                        className="block text-left px-4 py-2 text-sm dark:text-gray-300 data-[focus]:bg-darkmode-700 dark:hover:bg-darkmode-600 dark:hover:text-gray-500 w-full"
                      >
                        signet
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Menu>
              <Search
                setSearch={setSearch}
                className="w-full m-auto max-w-88 mr-0"
              />
            </div>
          </div>
          <div className="mt-6 font-thin bg-gray-300 rounded-t-lg flow-root dark:bg-darkmode-800 dark:darkscrollbar">
            <div className="overflow-x-auto dark:darkscrollbar">
              <div className="inline-block w-full align-middle">
                {!loading ? (
                  <table className="min-w-full rounded-t-lg">
                    <thead className="pl-3 rounded-t-lg bg-darkmode-700 border-darkmode-600 border-b-1">
                      <tr className="px-3 py-3 ml-3 text-left text-gray-900 rounded-t-lg dark:text-gray-500">
                        <th
                          scope="col"
                          className="py-3 pl-6 pr-3 text-xs font-normal text-left rounded-tl-lg whitespace-nowrap lg:pr-6 lg:text-sm"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="py-3 pr-3 text-xs font-normal text-left whitespace-nowrap lg:pr-6 lg:text-sm"
                        >
                          Ticker
                        </th>
                        <th
                          scope="col"
                          className="py-3 pr-3 text-xs font-normal text-left whitespace-nowrap lg:pr-6 lg:text-sm"
                        >
                          Supply
                        </th>
                        <th
                          scope="col"
                          className="hidden py-3 pr-3 text-xs font-normal text-left whitespace-nowrap lg:pr-6 lg:text-sm"
                        >
                          Precision
                        </th>
                        <th
                          scope="col"
                          className="py-3 pr-3 text-xs font-normal text-left whitespace-nowrap lg:pr-6 lg:text-sm"
                        >
                          Username
                        </th>
                        <th
                          scope="col"
                          className="py-3 pr-3 text-xs font-normal text-left whitespace-nowrap lg:pr-6 lg:text-sm"
                        >
                          Creation date
                        </th>
                        <th
                          scope="col"
                          className="py-3 pr-3 text-xs font-normal text-left whitespace-nowrap lg:pr-6 lg:text-sm"
                        >
                          Block height
                        </th>
                        <th
                          scope="col"
                          className="py-3 pr-3 text-xs font-normal text-left whitespace-nowrap lg:pr-6 lg:text-sm"
                        >
                          Contract ID
                        </th>
                        <th
                          scope="col"
                          className="py-3 pr-3 text-xs font-normal text-left rounded-tr-lg whitespace-nowrap lg:pr-6 lg:text-sm"
                        >
                          Genesis
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-full my-auto">
                      {assets.map((asset) => (
                        <tr
                          key={asset.id}
                          className="border-b-1 border-darkmode-600"
                        >
                          <td className="py-2 pl-6 pr-1 text-sm text-black lg:pr-3 whitespace-nowrap dark:text-gray-500">
                            {asset.number}
                          </td>
                          <td className="flex py-3 pr-3 text-sm text-black lg:pr-6 whitespace-nowrap dark:text-white flex-nowrap">
                            <div
                              className={classNames(
                                randColors[
                                  Math.floor(Math.random() * randColors.length)
                                ],
                                "rounded-full h-7 w-7 m-auto text-black ml-0 mr-1.5 flex justify-center"
                              )}
                            >
                              <p className="m-auto">{asset.ticker.charAt(0)}</p>
                            </div>
                            <p className="m-auto ml-0">{asset.ticker}</p>
                          </td>
                          <td className="py-2 pr-3 text-sm text-gray-900 lg:pr-6 whitespace-nowrap dark:text-white">
                            {Number(asset.supply) > 100000
                              ? getLargeNumber(Number(asset.supply))
                              : Number(asset.supply).toLocaleString()}
                          </td>
                          <td className="hidden py-2 pr-3 text-sm text-black lg:pr-6 whitespace-nowrap dark:text-white">
                            {asset.precision}
                          </td>
                          <td className="py-2 pr-3 text-sm text-black lg:pr-6 whitespace-nowrap dark:text-white">
                            {asset.username}
                          </td>
                          <td className="py-2 pr-3 text-sm text-black lg:pr-6 whitespace-nowrap dark:text-white">
                            {new Date(asset.timestamp).toLocaleDateString()}
                          </td>
                          <td className="py-2 pr-3 text-sm text-black lg:pr-6 whitespace-nowrap dark:text-white">
                            {asset.blockHeight}
                          </td>
                          <td className="py-2 pr-3 text-xs lg:pr-6 whitespace-nowrap max-w-36">
                            <button
                              type="button"
                              className="flex flex-row truncate flex-nowrap text-yellow-500 py-1 px-1.5 rounded-md hover:text-opacity-50"
                              onClick={() => {
                                navigator.clipboard.writeText(asset.contractId);
                                setOpenCopyModal(true);
                              }}
                            >
                              <p className="truncate max-w-28">Contract ID</p>
                              <Copy className="w-5 h-5 my-auto ml-1" />
                            </button>
                          </td>
                          <td className="py-2 pr-3 text-xs lg:pr-6 whitespace-nowrap max-w-36">
                            <button
                              type="button"
                              className="flex flex-row truncate flex-nowrap text-yellow-500 py-1 px-1.5 rounded-md hover:text-opacity-50"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  asset.contractGenesis
                                );
                                setOpenCopyModal(true);
                              }}
                            >
                              {" "}
                              <p className="truncate max-w-28">Genesis</p>
                              <Copy className="w-5 h-5 my-auto ml-1" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="block w-full h-full m-auto mb-6 overflow-y-hidden text-center align-middle mt-9">
                    <Loader className="w-16 h-16 m-auto text-center align-middle" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Pagination page={page} setPage={setPage} amount={amount} />
        </div>
      </div>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
    </div>
  );
};

export default Registry;
