import React from "react";

const LightningNetwork = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 20 20"
  >
    <g clipPath="url(#clip0_724_907)">
      <path
        d="M19.7007 12.4191C18.3649 17.7763 12.9384 21.0365 7.58001 19.7006C2.22382 18.365 -1.03684 12.9388 0.29955 7.58198C1.63469 2.22422 7.06121 -1.03637 12.418 0.299246C17.7761 1.63486 21.0364 7.06168 19.7007 12.4191Z"
        fill="#6A00F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7175 4.57593C12.823 4.08359 12.2402 3.74097 11.8613 4.07253L5.17973 9.91892C4.81614 10.2371 5.04115 10.8359 5.52427 10.8359H7.67429L6.6911 15.4241C6.5856 15.9165 7.16831 16.2591 7.54724 15.9275L14.2288 10.0811C14.5924 9.76299 14.3674 9.16415 13.8843 9.16415H11.7343L12.7175 4.57593Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_724_907">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LightningNetwork;
