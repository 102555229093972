import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { classNames } from "src/Hooks/util";

interface PinPadProps {
  // eslint-disable-next-line no-unused-vars
  onPinEntered: (pin: boolean) => void;
  pin: string;
  setPin: Dispatch<SetStateAction<string>>;
}

const PinPad: React.FC<PinPadProps> = ({ onPinEntered, pin, setPin }) => {
  const pinPadRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pinPadRef.current) {
      pinPadRef.current.focus();
    }
  }, []);

  const handlePinEntry = (value: string) => {
    if (pin.length === 5) {
      onPinEntered(true);
    }
    setPin((prevPin) => prevPin + value);
  };

  const handleClearPin = () => {
    onPinEntered(false);
    setPin("");
  };

  const handleDeleteDigit = () => {
    onPinEntered(false);
    setPin((prevPin) => prevPin.slice(0, -1));
  };

  const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLDivElement>) => {
    if (/^\d$/.test(key) && pin.length < 6) {
      handlePinEntry(key);
    } else if (key === "Backspace") {
      handleDeleteDigit();
    }
  };

  const displayPin = () => {
    let pinDots = "";
    pin.split("").forEach(() => {
      pinDots += "●";
    });
    return (
      <div className="tracking-[0.5rem] m-auto w-auto pl-2 py-3">{pinDots}</div>
    );
  };

  const rows = [
    ["1", "2", "3"],
    ["4", "5", "6"],
    ["7", "8", "9"],
    ["key", "0", ""],
  ];

  return (
    <div
      role="button"
      ref={pinPadRef}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      className="flex flex-col justify-center w-full text-black dark:text-white focus:ring-0 focus:outline-none"
    >
      {displayPin()}
      {rows.map((row) => (
        <div
          key={row.join("")}
          className="w-full grid grid-cols-3 dark:divide-newdarkmode-700 divide-x-1"
        >
          {row.map((value) => (
            <>
              {!["", "key"].includes(value) ? (
                <button
                  type="button"
                  key={value}
                  onClick={() => handlePinEntry(value)}
                  className={classNames(
                    value === "0"
                      ? ""
                      : "border-b-1 dark:border-newdarkmode-700",
                    "col-span-1 w-auto py-3 text-xl hover:bg-gray-300 dark:hover:bg-newdarkmode-800 disabled:pointer-events-none"
                  )}
                  disabled={pin.length >= 6}
                >
                  {value === "key" ? "" : value}
                </button>
              ) : (
                <div key={value} className="w-auto py-3 col-span-1" />
              )}
            </>
          ))}
        </div>
      ))}
      <div className="w-full text-lg font-thin grid grid-cols-3">
        <button
          className="w-auto py-3 col-span-1 dark:hover:text-gray-500 hover:text-gray-700"
          type="button"
          onClick={handleClearPin}
        >
          Clear
        </button>
        <button
          className="w-auto py-3 col-span-1 dark:hover:text-gray-500 hover:text-gray-700"
          type="button"
          onClick={handleDeleteDigit}
        >
          Delete
        </button>
        <div className="w-auto py-3 col-span-1" />
      </div>
    </div>
  );
};

export default PinPad;
