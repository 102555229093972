/* eslint-disable @typescript-eslint/no-explicit-any */

import { setMany, getMany, delMany, clear } from "idb-keyval";
import { StorageResult } from "src/types";

type IDBValidKey = number | string | Date | IDBValidKey[];

export const storageSet = async (query: any) => {
  const entries: [IDBValidKey, any][] = Object.entries(query).map(
    ([key, val]: [IDBValidKey, any]) => [
      key,
      JSON.stringify(val, (_key, value) =>
        typeof value === "bigint" ? value.toString() : value
      ),
    ]
  );
  await setMany(entries);
};

export const storageGet = async <T>(
  keys: string[]
): Promise<StorageResult<T>> => {
  const vals = await getMany(keys);
  return vals.reduce((acc, val, i) => {
    if (val) {
      let parsedVal = JSON.parse(val);
      if (typeof parsedVal === "string" && isValidJSON(parsedVal))
        parsedVal = JSON.parse(parsedVal);
      acc[keys[i]] = parsedVal;
    }
    return acc;
  }, {});
};

export const storageDel = async (keys) => {
  await delMany(keys);
};

export const storageClear = () => clear();

const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};
