/* eslint-disable react/destructuring-assignment */
import React from "react";

const ImportAssetTabs = ({ className = "", ...props }) => (
  <div
    className={`${className} bg-gray-100 lightbg-darkmode-900 dark:bg-darkmode-900 rounded-t-xl`}
  >
    <nav className="flex flex-row flex-1 w-full h-12 m-auto">
      {props.tabs.map((tab, index) => (
        <button
          key={tab.title}
          type="button"
          className={`text-black text-sm sm:text-base dark:text-gray-600 px-4 block focus:outline-none dark:hover:text-yellow-500 font-medium w-full ${
            props.view === index
              ? `dark:text-yellow-500 border-b-2 border-yellow-500`
              : `border-b-2 border-gray-300 dark:border-newdarkmode-700`
          }`}
          onClick={() => {
            props.setView(index);
          }}
        >
          {tab.title}
        </button>
      ))}
    </nav>
  </div>
);

export default ImportAssetTabs;
