import React from "react";

const RgbAsset = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} cursor-pointer`}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0.1"
    viewBox="0 0 48 48"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.3663 20.7362C41.7515 21.5449 39.9289 22 38 22C31.3726 22 26 16.6274 26 10C26 8.0711 26.4551 6.24849 27.2638 4.63371C25.5773 4.21961 23.8143 4 22 4C9.84974 4 0 13.8497 0 26C0 38.1503 9.84974 48 22 48C34.1503 48 44 38.1503 44 26C44 24.1857 43.7804 22.4228 43.3663 20.7362Z"
      fill="#FFCB12"
      fillOpacity="0.1"
    />
    <path
      d="M22 18C28.075 18 33 20.686 33 24V28C33 31.314 28.075 34 22 34C16.033 34 11.176 31.409 11.005 28.177L11 28V24C11 20.686 15.925 18 22 18ZM22 30C18.28 30 14.99 28.993 13 27.45V28C13 29.882 16.883 32 22 32C27.01 32 30.838 29.97 30.995 28.118L31 28L31.001 27.45C29.011 28.992 25.721 30 22 30ZM22 20C16.883 20 13 22.118 13 24C13 25.882 16.883 28 22 28C27.117 28 31 25.882 31 24C31 22.118 27.117 20 22 20Z"
      fill="#FFCB12"
    />
    <path
      d="M47.7007 12.4191C46.3649 17.7763 40.9384 21.0365 35.58 19.7006C30.2238 18.365 26.9632 12.9388 28.2996 7.58198C29.6347 2.22422 35.0612 -1.03637 40.418 0.299246C45.7761 1.63486 49.0364 7.06168 47.7007 12.4191Z"
      fill="#282828"
    />
    <path
      d="M33.2559 5.78205L34.0931 7.21795H44V6.5L38 3L33.2559 5.78205Z"
      fill="#FF0C35"
    />
    <path
      d="M32.7907 6.05128L37.5814 13.9936L36.3256 16.0128L32 13.5V6.5L32.7907 6.05128Z"
      fill="#1BEA39"
    />
    <path
      d="M44.0003 13.5V7.71154H42.2329L36.791 16.3269L38.0468 17L44.0003 13.5Z"
      fill="#3E90F0"
    />
  </svg>
);

export default RgbAsset;
