import React from "react";

import { CARBONADO } from "bitmask-core/constants";
import { TEST_IDS } from "tests/ids";

const Tabs = ({ className = "", ...props }) => (
  <div className={`${className}`}>
    <nav className="flex flex-row flex-1 w-full h-12 m-auto text-sm sm:text-base">
      <button
        type="button"
        className={`group m-auto py-2 px-4 flex flex-row justify-center group-hover:text-gray-700 dark:group-hover:text-yellow-500 font-medium w-full ${
          props.view === 0
            ? `dark:text-yellow-500 border-b-2 border-yellow-500`
            : `text-black dark:text-gray-600 dark:hover:text-yellow-500 border-b-1/2 border-gray-300 dark:border-newdarkmode-600`
        }`}
        onClick={() => {
          props.setView(0);
        }}
        data-test-id={TEST_IDS.wallet.tabs.activity}
      >
        <p className="my-auto">Activities </p>
        <div
          className={`flex justify-center my-auto ml-2 text-center rounded-full h-7 min-w-7 w-auto ${
            props.view === 0
              ? `dark:bg-yellow-500 bg-yellow-500 dark:bg-opacity-10 bg-opacity-10 dark:text-yellow-500 text-gray-900`
              : `text-gray-700 bg-gray-300 dark:text-gray-600 dark:bg-darkmode-600 group-hover:bg-yellow-500 group-hover:text-black dark:group-hover:text-newdarkmode-900`
          }`}
        >
          <p className="px-2 my-auto text-xs sm:text-sm">
            {props.activitiesLen ? props.activitiesLen : 0}
          </p>
        </div>
      </button>
      <button
        type="button"
        className={`group m-auto py-2 px-4 flex flex-row justify-center group-hover:text-gray-700 dark:group-hover:text-yellow-500 font-medium w-full disabled:opacity-50 disabled:cursor-not-allowed ${
          props.view === 1
            ? `dark:text-yellow-500  border-b-2 border-yellow-500`
            : `text-black dark:text-gray-600 dark:hover:text-yellow-500 border-b-1/2 border-gray-300 dark:border-newdarkmode-600`
        }`}
        onClick={() => {
          props.setView(1);
        }}
        data-test-id={TEST_IDS.wallet.tabs.assets}
        disabled={!CARBONADO}
      >
        <p className="my-auto">
          Tokens <span className="hidden sm:inline">(RGB20)</span>
        </p>
        <div
          className={`flex justify-center my-auto ml-2 text-center rounded-full h-7 min-w-7 w-auto ${
            props.view === 1
              ? `dark:bg-yellow-500 bg-yellow-500 dark:bg-opacity-10 bg-opacity-10 dark:text-yellow-500 text-gray-900`
              : `text-gray-700 bg-gray-300 dark:text-gray-600 dark:bg-darkmode-600 group-hover:bg-yellow-500 group-hover:text-black dark:group-hover:text-newdarkmode-900`
          }`}
        >
          <p className="px-2 my-auto text-xs sm:text-sm">{props.assetsLen}</p>
        </div>
      </button>
      <button
        type="button"
        className={`group m-auto py-2 px-4 flex flex-row justify-center group-hover:text-gray-700 dark:group-hover:text-yellow-500 font-medium w-full disabled:opacity-50 disabled:cursor-not-allowed ${
          props.view === 2
            ? `dark:text-yellow-500  border-b-2 border-yellow-500`
            : ` dark:text-gray-600 dark:hover:text-yellow-500 border-b-1/2 border-gray-300 dark:border-newdarkmode-600`
        }`}
        onClick={() => {
          props.setView(2);
        }}
        data-test-id={TEST_IDS.wallet.tabs.uda}
        disabled={!CARBONADO}
      >
        <p className="my-auto">
          UDAs <span className="hidden sm:inline">(RGB21)</span>
        </p>
        <div
          className={`flex justify-center my-auto ml-2 text-center rounded-full h-7 min-w-7 w-auto ${
            props.view === 2
              ? `dark:bg-yellow-500 bg-yellow-500 dark:bg-opacity-10 bg-opacity-10 dark:text-yellow-500 text-gray-900`
              : `text-gray-700 bg-gray-300 dark:text-gray-600 dark:bg-darkmode-600 group-hover:bg-yellow-500 group-hover:text-black dark:group-hover:text-newdarkmode-900`
          }`}
        >
          <p className="px-2 my-auto text-xs sm:text-sm">
            {props.collectionsLen}
          </p>
        </div>
      </button>
    </nav>
  </div>
);

export default Tabs;
