export default class ExecuteStep {
  public usecase: string;

  public result: string[];

  constructor(usecase: string) {
    this.usecase = usecase;
    this.result = [];
  }

  public ok(event: string) {
    console.log(`${event} .... ok`);

    this.result.push(`${event} .... ok`);
  }

  public failt(event: string) {
    console.log(`${event} .... fail`);
    this.result.push(`${event} .... fail`);
  }
}
