import React from "react";

const QuestionMarkCircle = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} cursor-pointer`}
    fill="none"
    strokeWidth="0.1"
    viewBox="0 0 44 44"
  >
    <g clipPath="url(#clip0_228_8273)">
      <circle cx="22" cy="22" r="22" fill="#FFCB12" fillOpacity="0.1" />
      <path
        d="M22 32C16.477 32 12 27.523 12 22C12 16.477 16.477 12 22 12C27.523 12 32 16.477 32 22C32 27.523 27.523 32 22 32ZM22 30C24.1217 30 26.1566 29.1571 27.6569 27.6569C29.1571 26.1566 30 24.1217 30 22C30 19.8783 29.1571 17.8434 27.6569 16.3431C26.1566 14.8429 24.1217 14 22 14C19.8783 14 17.8434 14.8429 16.3431 16.3431C14.8429 17.8434 14 19.8783 14 22C14 24.1217 14.8429 26.1566 16.3431 27.6569C17.8434 29.1571 19.8783 30 22 30ZM21 25H23V27H21V25ZM23 23.355V24H21V22.5C21 22.2348 21.1054 21.9804 21.2929 21.7929C21.4804 21.6054 21.7348 21.5 22 21.5C22.2841 21.5 22.5623 21.4193 22.8023 21.2673C23.0423 21.1154 23.2343 20.8984 23.3558 20.6416C23.4773 20.3848 23.5234 20.0988 23.4887 19.8168C23.454 19.5349 23.34 19.2686 23.1598 19.0489C22.9797 18.8292 22.7409 18.6652 22.4712 18.576C22.2015 18.4867 21.912 18.4759 21.6364 18.5447C21.3608 18.6135 21.1104 18.7592 20.9144 18.9648C20.7183 19.1704 20.5847 19.4274 20.529 19.706L18.567 19.313C18.6886 18.7051 18.9695 18.1404 19.3809 17.6766C19.7923 17.2128 20.3195 16.8666 20.9086 16.6733C21.4977 16.4801 22.1275 16.4467 22.7337 16.5766C23.3399 16.7065 23.9007 16.9951 24.3588 17.4128C24.8169 17.8305 25.1559 18.3624 25.3411 18.9541C25.5263 19.5457 25.5511 20.1759 25.4129 20.7803C25.2747 21.3847 24.9785 21.9415 24.5545 22.3939C24.1306 22.8462 23.5941 23.1779 23 23.355Z"
        fill="#FFCB12"
      />
    </g>
    <defs>
      <clipPath id="clip0_228_8273">
        <rect width="44" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default QuestionMarkCircle;
