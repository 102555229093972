import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import * as backend from "bitmask-core";

import lib, { isExtension } from "src/lib";
import { Preload } from "src/types";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Loader from "src/Components/Loaders/Loader";
import { classNames, setHash } from "src/Hooks/util";

const PaperWallet = () => {
  const { CONTEXT = "" } = process.env;
  const { pin: customPin } = useParams();
  const [link, setLink] = useState("");
  const [path, setPath] = useState("");
  const [pin, setPin] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await lib.storageClear();
      const pinPass =
        customPin ||
        Math.round(Math.random() * 1_000_000).toLocaleString(undefined, {
          minimumIntegerDigits: 6,
          useGrouping: false,
        });
      setPin(pinPass);
      const hash = backend.bitcoin.hashPassword(pinPass);
      const encryptedDescriptors = await backend.bitcoin.newWallet(
        hash, // 0 in 32 bytes
        pinPass
      );
      // TODO: add cassete number field
      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );

      await setHash(hash, vault.public.xpubkh);

      const walletData = await backend.bitcoin.getWalletData(
        vault.private.btcDescriptorXprv,
        vault.private.btcChangeDescriptorXprv
      );

      setAddress(walletData.address);

      const preload: Preload = {
        seed: vault.mnemonic,
        context: CONTEXT,
        hash,
      };
      const encoded = btoa(JSON.stringify(preload));
      setPath(`/walletimport/${encoded}`);
      setLink(`${window.location.origin}/index.html/#/walletimport/${encoded}`);
    })();
  }, []);

  if (link.length > 0) {
    return (
      <div className="flex flex-col justify-center w-full h-auto min-h-full m-auto overflow-y-auto bg-gray-100 dark:bg-darkmode-900 dark:bg-newdarkmode-700">
        <h1 className="mb-6 text-lg font-medium text-center text-black sm:text-xl xl:text-3xl 3xl:text-4xl dark:text-white flex-grow-default">
          Generate Paper Wallet Link
        </h1>
        <div className="flex flex-col content-center justify-center w-full h-auto max-w-3xl mx-auto">
          <h2 className="text-lg font-medium text-center text-black underline sm:text-xl xl:text-2xl dark:text-white flex-grow-default">
            <a href={link}>Wallet on {window.location.host}</a>
          </h2>
          <h3 className="text-lg font-medium text-center text-black break-words sm:text-xl xl:text-2xl dark:text-white flex-grow-default">
            {link}
          </h3>
          <h3 className="text-lg font-medium text-center text-black sm:text-xl xl:text-2xl dark:text-white flex-grow-default">
            Pin / Pass: {pin}
          </h3>
          <h3 className="text-lg font-medium text-center text-black sm:text-xl xl:text-2xl dark:text-white flex-grow-default">
            Address: {address}
          </h3>
          <div className="w-11/12 mx-auto my-12 shadow-xl dark:bg-gray-300 rounded-xl md:w-full">
            <QRCode
              value={link}
              className="max-w-[275px] sm:max-w-[375px] sm:w-[90%] h-auto mx-auto my-6"
              size={256}
              viewBox="0 0 256 256"
            />
          </div>
          <RoundedButton
            className="py-3 m-auto mt-6 text-lg text-black bg-yellow-500 px-9 xl:text-xl"
            onClick={() => {
              navigate(path);
            }}
          >
            Open Wallet
          </RoundedButton>
        </div>
      </div>
    );
  }
  return (
    <div
      className={classNames(
        isExtension ? "min-w-[400px]" : "min-w-80",
        "flex justify-center w-full h-screen m-auto text-gray-900 dark:text-gray-300 bg-gray-100 dark:bg-darkmode-900"
      )}
    >
      <Loader className="w-20 h-20 m-auto" />
    </div>
  );
};

export default PaperWallet;
