/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable arrow-body-style */
import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { classNames } from "src/Hooks/util";

const Pagination = ({ ...props }) => {
  const roundToNearestTen = (num: number): number => {
    const nearestTen = Math.ceil(num / 10) * 10;
    return nearestTen;
  };
  const createArrayFromNumber = (n: number): number[] => {
    const result: number[] = [];
    for (let i = 1; i <= n; i += 1) {
      result.push(i);
    }
    return result;
  };

  return (
    <div className="flex items-center justify-between px-4 py-3 border-gray-200 rounded-b-lg bg-gray-350 border-t-1 dark:border-newdarkmode-600 dark:bg-newdarkmode-800 sm:px-6">
      <div className="flex justify-between flex-1 md:hidden">
        <button
          type="button"
          className="relative inline-flex items-center px-2 py-2 text-gray-400 bg-gray-400 rounded-md hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:bg-newdarkmode-800 shadow-sm"
          onClick={() => {
            props.setPage(props.page - 1);
          }}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon
            className="w-5 h-5 text-gray-800 dark:text-gray-300"
            aria-hidden="true"
          />
        </button>
        <button
          type="button"
          className="relative inline-flex items-center px-2 py-2 text-gray-400 bg-gray-400 rounded-md hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:bg-newdarkmode-800 shadow-sm"
          onClick={() => {
            props.setPage(props.page + 1);
          }}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon
            className="w-5 h-5 text-gray-800 dark:text-gray-300"
            aria-hidden="true"
          />
        </button>
      </div>
      <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
        <div>
          <p className="text-sm text-gray-900 dark:text-white">
            Showing{" "}
            <span className="font-medium">
              {props.page === 1 ? 1 : `${(props.page - 1).toString()}1`}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {props.page === 1
                ? props.amount > 10
                  ? 10
                  : props.amount
                : props.amount - props.page * 10 > 0
                ? 10 * props.page
                : props.amount}
            </span>{" "}
            of <span className="font-medium">{props.amount}</span> results
          </p>
        </div>
        <div>
          <nav
            className="inline-flex isolate -space-x-px rounded-md gap-3"
            aria-label="Pagination"
          >
            <button
              type="button"
              className="relative inline-flex items-center px-2 py-2 text-gray-400 bg-gray-400 rounded-md hover:bg-gray-500 focus:z-20 focus:outline-offset-0 dark:bg-newdarkmode-800 dark:hover:bg-newdarkmode-900 shadow-sm disabled:pointer-events-none"
              onClick={() => {
                if (props.page > 1) {
                  props.setPage(props.page - 1);
                }
              }}
              disabled={props.page <= 1}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon
                className="w-5 h-5 text-gray-800 dark:text-gray-300"
                aria-hidden="true"
              />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 hover:bg-gray-50 focus:outline-offset-0" */}
            {createArrayFromNumber(roundToNearestTen(props.amount) / 10)
              .length > 6 ? (
              <>
                {[
                  ...createArrayFromNumber(
                    roundToNearestTen(props.amount) / 10
                  ).slice(0, 3),
                  "...",
                  ...createArrayFromNumber(
                    roundToNearestTen(props.amount) / 10
                  ).slice(-3),
                ].map((page) => (
                  <button
                    type="button"
                    className={classNames(
                      props.page === page || page === "..."
                        ? "bg-gray-400 dark:bg-newdarkmode-800 border-gray-600 dark:border-gray-300 border-1"
                        : "hover:bg-gray-400 dark:hover:bg-newdarkmode-800",
                      page === "..."
                        ? "border-none !bg-transparent"
                        : "px-4 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 rounded-md shadow-sm",
                      "relative z-10 inline-flex items-center py-2 text-sm font-semibold text-black dark:text-white"
                    )}
                    onClick={() => {
                      props.setPage(page);
                    }}
                    disabled={page === "..."}
                    key={page}
                  >
                    <span className="">{page}</span>
                  </button>
                ))}
              </>
            ) : (
              <>
                {createArrayFromNumber(
                  roundToNearestTen(props.amount) / 10
                ).map((page) => (
                  <button
                    type="button"
                    className={classNames(
                      props.page === page
                        ? "bg-gray-400 dark:bg-newdarkmode-800 border-gray-600 dark:border-gray-300 border-1"
                        : "hover:bg-gray-400 dark:hover:bg-newdarkmode-800",
                      "relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold text-black dark:text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 rounded-md shadow-sm"
                    )}
                    onClick={() => {
                      props.setPage(page);
                    }}
                    key={page}
                  >
                    <span className="">{page}</span>
                  </button>
                ))}
              </>
            )}
            <button
              type="button"
              className="relative inline-flex items-center px-2 py-2 text-gray-400 bg-gray-400 rounded-md hover:bg-gray-500 focus:z-20 focus:outline-offset-0 dark:bg-newdarkmode-800 dark:hover:bg-newdarkmode-900 shadow-sm disabled:pointer-events-none"
              onClick={() => {
                if (props.amount > 10 * props.page) {
                  props.setPage(props.page + 1);
                }
              }}
              disabled={props.amount <= 10 * props.page}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon
                className="w-5 h-5 text-gray-800 dark:text-gray-300"
                aria-hidden="true"
              />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
