/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as backend from "bitmask-core";
import { Activity, Transaction } from "bitmask-core/bitcoin";

import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import ErrorModal from "src/Components/Modals/Error";
import lib from "src/lib";
import { getConversion, getFeeRate } from "src/Hooks/util";
import { Location } from "src/types";
import { ifaceFungibles, ifaceUdas } from "src/constants";
import { transferAssetsWithFeeRate } from "src/aux/transferAssets";

// TODO: clarify Asset, Assets and token naming

const updateActivities = async (transaction: Transaction, wallet: string) => {
  if (
    transaction.assetType === "Asset" ||
    transaction.assetType === "UDA" ||
    transaction.assetType === "Assets" ||
    transaction.assetType === "token"
  ) {
    const activity: Activity = {
      date: Date.now(),
      id: uuidv4(),
      action: "sent",
      status: "pending",
      ...transaction,
    };
    const key = `${wallet}.asset_activities`;
    const result = await lib.storageGet<Activity[]>([key]);
    let activities: Activity[] = [];
    if (result[key]) {
      activities = result[key];
    }
    activities.push(activity);
    await lib.storageSet({ [key]: activities });
    console.debug(`activities: ${activities}`);
  }
};

const Confirm = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    transaction: transactionBase,
    walletData,
    vault,
    asset,
    locationHistory,
    hash,
    lnCredentials,
    feeRate,
  } = location.state;

  const network = window.localStorage.getItem("network");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [conversion, setConversion] = useState(0);

  const transaction: Activity = {
    ...transactionBase,
    amount: transactionBase.assetType === "UDA" ? 1 : transactionBase.amount,
  };
  console.log(transaction, "transactionBase");

  const handleSubmit = async () => {
    try {
      if (
        transaction.assetType === "Asset" ||
        transaction.assetType === "token" ||
        transaction.assetType === "UDA" ||
        transaction.assetType === "Assets"
      ) {
        const optimalFee = await getFeeRate();
        if (feeRate > optimalFee * 2 || feeRate < 1) {
          setError({
            title: `Error sending payment`,
            message: `Fee rate is set out of range, allowed range is between 1 and ${
              optimalFee * 2
            } sats/vByte.`,
          });
          setOpen(true);
        } else {
          setLoading(true);
          if (!transaction.recipient?.invoice) {
            throw new Error("No invoice provided");
          }
          if (!transaction.asset) {
            throw new Error("No transaction asset provided");
          }
          const iface =
            transaction.assetType === "UDA" ? ifaceUdas : ifaceFungibles;

          const { sign, consig, txid } = await transferAssetsWithFeeRate(
            vault,
            asset,
            transaction.recipient?.invoice,
            iface,
            feeRate !== 0 ? feeRate : optimalFee
          );
          transaction.id = txid;
          if (sign) {
            console.info("Asset transfer consignment:", consig);
            updateActivities(transaction, walletData.name);
            navigate("/success", {
              state: {
                wallet: walletData.name,
                vault,
                hash,
                transaction,
                lnCredentials,
                asset,
                consignment: consig,
              },
            });
          } else {
            setError({
              title: `Error sending payment`,
              message: "Error validating transaction",
            });
            setOpen(true);
          }
        }
      } else {
        const optimalFee = await getFeeRate();
        if (feeRate > optimalFee * 2 || feeRate < 1) {
          setError({
            title: `Error sending payment`,
            message: `Fee rate is set out of range, allowed range is between 1 and ${
              optimalFee * 2
            } sats/vByte.`,
          });
          setOpen(true);
        } else {
          setLoading(true);

          if (!transaction.recipient?.address) {
            throw new Error("No address for transaction recipient provided");
          }

          // If transaction amount is for the same number of sats in the wallet, drain the wallet
          if (transaction.amount === walletData.balance.confirmed) {
            const txResponse = await backend.bitcoin.drainWallet(
              transaction.recipient.address,
              vault.private.btcDescriptorXprv,
              vault.private.btcChangeDescriptorXprv,
              feeRate !== 0 ? feeRate : optimalFee
            );

            transaction.id = txResponse.details.txid;
          } else {
            const txResponse = await backend.bitcoin.sendSats(
              vault.private.btcDescriptorXprv,
              vault.private.btcChangeDescriptorXprv,
              transaction.recipient.address,
              BigInt(transaction.amount),
              true,
              feeRate !== 0 ? feeRate : optimalFee
            );

            transaction.id = txResponse.details.txid;
          }

          updateActivities(transaction, walletData.name);

          navigate("/success", {
            state: {
              wallet: walletData.name,
              vault,
              transaction,
              hash,
              lnCredentials,
            },
          });
        }
      }
    } catch (err: any) {
      console.error(err);
      setLoading(false);
      if (err.toString().includes("InsufficientFunds")) {
        setError({
          title: `Error sending payment`,
          message:
            "Insufficient funds. If is correct, try again later or contact support.",
        });
      } else if (err.toString().includes("min relay fee not met")) {
        setError({
          title: `Error sending payment`,
          message:
            "We are working correcting this error. Try again maxing fees or contact support.",
        });
      } else if (err.toString().includes("Error on address")) {
        setError({
          title: `Error sending payment`,
          message:
            "Invalid address. Try again with a valid address or contact support.",
        });
      } else {
        setError({
          title: `Error sending payment`,
          message: JSON.stringify(err),
        });
      }
      setOpen(true);
    }
  };

  useEffect(() => {
    (async () => {
      const rate = await getConversion();
      setConversion(rate);
    })();
  }, []);

  return (
    <PageWrapper
      title="Confirm Transaction"
      handlePageBack={() =>
        navigate(locationHistory.pop()?.replace("#", "") || "/", {
          state: {
            ...location.state,
          },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleSubmit={() => handleSubmit()}
      loading={loading}
      confirmation
    >
      <div className="sm:p-12">
        <div className="p-6 m-auto mb-6 text-center sm:mb-12 border-1 dark:border-darkmode-700 rounded-xl">
          <p className="my-auto text-base sm:text-lg font-thin text-gray-800 truncate dark:text-gray-500 mb-1.5">
            Sending
          </p>
          <p className="mb-3 text-2xl font-semibold text-black dark:text-gray-300">
            {transaction.assetType !== "UDA" &&
              transaction.amount.toLocaleString()}{" "}
            {asset.ticker}
          </p>
          {["sats", "Lightning"].includes(transaction.assetType) && (
            <div className="flex flex-row justify-center flex-nowrap gap-3">
              <p className="text-base font-thin text-gray-800 dark:text-gray-500">
                {Number(transaction.amount / 100_000_000).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 8,
                  }
                )}{" "}
                BTC
              </p>
              <p className="text-base font-thin text-gray-800 dark:text-gray-500">
                {Number(
                  (Number(transaction.amount) / 100_000_000) * conversion
                ).toLocaleString(undefined, { minimumFractionDigits: 3 })}{" "}
                USD
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col mt-6 font-light">
          <div className="flex flex-row w-full py-4 m-auto flex-nowrap border-b-1 dark:border-darkmode-700">
            <p className="my-auto mr-auto text-base font-thin text-gray-700 sm:text-lg dark:text-gray-500">
              From
            </p>
            <p className="my-auto text-base font-thin text-right text-gray-800 truncate sm:text-lg dark:text-white max-w-64">
              {transaction.sender?.address}
            </p>
          </div>
          <div className="flex flex-row w-full py-4 m-auto flex-nowrap border-b-1 dark:border-darkmode-700">
            <p className="my-auto mr-auto text-base font-thin text-gray-700 sm:text-lg dark:text-gray-500">
              To
            </p>
            <p className="my-auto text-base font-thin text-right text-gray-800 truncate sm:text-lg dark:text-white max-w-64">
              {transaction.recipient?.address
                ? transaction.recipient?.address
                : transaction.recipient?.invoice}
            </p>
          </div>
        </div>
        <div className="hidden">
          {network === "bitcoin" && (
            <>
              <p className="mt-4 mb-1 text-sm font-semibold text-gray-600 dark:text-gray-500">
                Transaction Fee
              </p>
              <div className="grid grid-cols-2">
                <div className="mr-5">
                  <div className="flex flex-col w-auto px-2 py-1 m-auto mt-1 text-center rounded-lg">
                    <p className="m-auto text-base text-black truncate ont-light dark:text-gray-300">
                      {transaction.fee.toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {transaction.assetType !== "UDA" && (
          <div className="flex-col mt-6 flex-grow-default">
            <div className="flex flex-nowrap">
              <p className="mb-1 text-base font-semibold text-gray-700 sm:text-lg dark:text-gray-400">
                Total
              </p>
              <p className="my-auto ml-auto text-lg font-medium text-right text-black truncate dark:text-white">
                {transaction.amount.toLocaleString()} {asset.ticker}
              </p>
            </div>
            <div className="flex flex-nowrap">
              {["sats", "Lightning"].includes(transaction.assetType) && (
                <p className="mt-1 text-base font-thin text-gray-800 dark:text-gray-500">
                  Amount + Fee
                </p>
              )}
              {["sats", "Lightning"].includes(transaction.assetType) && (
                <p className="my-auto ml-auto text-base font-thin text-right text-gray-800 truncate dark:text-gray-500">
                  $
                  {(
                    (Number(transaction.amount) / 100_000_000) * conversion +
                    (transaction.fee / 100_000_000) * conversion
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 3,
                  })}{" "}
                  USD
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default Confirm;
