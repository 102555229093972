import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const HomeAuthWrapper = () => {
  const loggedIn = localStorage.getItem("loggedIn");

  return !loggedIn ? <Outlet /> : <Navigate to="/home" replace />;
};

export default HomeAuthWrapper;
