/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
import React from "react";
import { Disclosure } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { classNames } from "src/Hooks/util";
import { isExtension } from "src/lib";
import LogoDark from "src/Components/Icons/LogoDark";
import HamburgerMenu from "src/Components/Icons/HamburgerMenu";
import XMarkIcon from "src/Components/Icons/XMarkIcon";
import Telegram from "src/Components/Icons/Telegram";
import Twitter from "src/Components/Icons/Twitter";
import Discord from "src/Components/Icons/Discord";
import Instagram from "src/Components/Icons/Instagram";

const SecondaryNav = ({ className, ...props }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Disclosure
      as="header"
      className={classNames(
        className,
        ["/", "/faq"].includes(pathname) ? "h-18 max-h-18" : "h-14 max-h-14",
        "relative z-60"
      )}
    >
      {({ open, close }) => (
        <>
          <div className="h-full min-w-full my-auto">
            <div className="relative w-full h-full my-auto">
              <div className="flex w-full h-full px-4 flex-nowrap">
                <a
                  type="button"
                  className="my-auto mr-auto text-white"
                  href={isExtension ? "/index.html" : "/"}
                >
                  <LogoDark
                    className={
                      props.menuItems
                        ? `m-auto h-14 w-36 xs:pl-2 lg:pl-6 text-white`
                        : `m-auto h-14 w-36 md:pl-6 text-white`
                    }
                  />
                </a>
                <Disclosure.Button className="inline-flex items-center justify-center w-auto p-2 text-gray-500 rounded-md md:hidden xs:relative xs:top-0.5 xs:right-auto right-4 hover:text-gray-300">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon
                      className={classNames(
                        "block w-8 h-8 my-auto -pt-0.5 !bg-transparent !bg-none font-thin",
                        className
                      )}
                      aria-hidden="true"
                    />
                  ) : (
                    <HamburgerMenu
                      className={classNames(
                        className,
                        "order-2 w-8 h-5 mx-3 my-auto"
                      )}
                    />
                  )}
                </Disclosure.Button>
                <div className="hidden md:flex">
                  {props?.menuItems && (
                    <>
                      {props.menuItems.map((item) => (
                        <div
                          className={classNames(
                            "p-1 my-auto text-sm font-light md:mx-2 lg:mx-4 lg:text-base text-gray-500"
                          )}
                          key={item.name}
                        >
                          {item.link ? (
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                              className="!text-gray-500"
                            >
                              {item.name}
                            </a>
                          ) : (
                            <button
                              type="button"
                              onClick={
                                item.ref
                                  ? () => {
                                      item.ref.current.scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                      });
                                    }
                                  : () => navigate(item.route)
                              }
                            >
                              {item.name}
                            </button>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel
            className={classNames(
              className,
              "absolute z-40 w-full h-auto overflow-hidden min-h-page md:hidden"
            )}
          >
            <div className="flex flex-col pt-4 pb-3 border-t z-60 divide-y-1/2 divide-newdarkmode-700 divide-opacity-75">
              {props?.menuItems && (
                <>
                  {props.menuItems
                    .filter((item) => item.name !== undefined)
                    .map((item) => (
                      <div className="w-full" key={item.name}>
                        {item.link ? (
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                            className="!text-white hover:opacity-50"
                          >
                            <p className="w-full py-4 my-auto text-lg font-thin !text-gray-500 sm:py-6 sm:text-xl pl-9 lg:mx-6 md:text-sm hover:text-opacity-50">
                              {item.name}
                            </p>
                          </a>
                        ) : (
                          <button
                            type="button"
                            className="w-full py-4 my-auto text-lg font-thin text-left text-gray-500 sm:py-6 sm:text-xl pl-9 lg:mx-6 md:text-sm hover:text-opacity-50"
                            onClick={
                              item.ref
                                ? () => {
                                    close();
                                    item.ref.current.scrollIntoView({
                                      behavior: "smooth",
                                      block: "center",
                                    });
                                  }
                                : () => {
                                    close();
                                    navigate(item.route);
                                  }
                            }
                          >
                            {item.name}
                          </button>
                        )}
                      </div>
                    ))}
                </>
              )}
            </div>
            <div className="absolute inset-x-0 bottom-0 flex flex-row px-3 pb-12 dark:text-white">
              <a
                className="w-24 m-auto"
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/bitmask_app?s=21&t=VH4nq5qpcV5iPRa2Z4gEyA"
              >
                <Twitter className="w-10 h-10 m-auto text-gray-500 sm:w-10 sm:h-10" />
              </a>
              <a
                className="w-24 m-auto"
                target="_blank"
                rel="noreferrer"
                href="https://t.me/tryDIBA
"
              >
                <Telegram className="m-auto text-gray-500 w-14 h-14 sm:w-14 sm:h-14" />
              </a>
              <a
                className="w-24 m-auto"
                target="_blank"
                rel="noreferrer"
                href="https://discord.gg/RzKxTJg24K"
              >
                <Discord className="w-8 h-8 m-auto text-gray-500 sm:w-8 sm:h-8" />
              </a>
              <a
                className="w-24 m-auto"
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/trydiba?igshid=YWJhMjlhZTc="
              >
                <Instagram className="w-8 h-8 m-auto text-gray-500 sm:w-8 sm:h-8" />
              </a>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default SecondaryNav;
