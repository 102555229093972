/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */

import * as backend from "bitmask-core";
import { Vault } from "bitmask-core/bitcoin";
import {
  ContractResponse,
  IssueRequest,
  IssueResponse,
  MediaData,
  RgbInvoiceRequest,
  RgbInvoiceResponse,
  RgbTransferRequest,
  RgbTransferResponse,
} from "bitmask-core/rgb";
import { Network } from "bitmask-core/constants";

import React from "react";

import Step from "./Step";
import ExecuteStep from "./ExecuteStep";

interface RgbState {
  steps: ExecuteStep[];

  alice: Vault | undefined;
  aliceSk: string;
  aliceUtxo: string;
  aliceKeys: string[];

  bob: Vault | undefined;
  bobSk: string;
  bobBtcUtxo: string;
  bobRgbFun1Utxo: string;
  bobRgbUDA1Utxo: string;
  bobFun1Invoice: string;
  bobUDA1Invoice: string;

  ifaceUDA1: string;
  ifaceFun1: string;
  contractUDA1Id: string;
  contractUDA1: string;
  contractMedia: MediaData;

  contractFun1Id: string;
  contractFun1: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class Rgb2Utxo extends React.Component<{ value: string }, RgbState> {
  constructor(props) {
    super(props);

    this.state = {
      steps: [],

      alice: undefined,
      aliceSk: "",
      aliceUtxo: "",
      aliceKeys: [],

      bob: undefined,
      bobSk: "",
      bobBtcUtxo: "",
      bobRgbFun1Utxo: "",
      bobRgbUDA1Utxo: "",
      bobFun1Invoice: "",
      bobUDA1Invoice: "",

      ifaceUDA1: "RGB21",
      ifaceFun1: "RGB20",
      contractUDA1Id: "",
      contractUDA1: "",
      contractMedia: {
        type: "image/svg+xml",
        uri: "https://bitcoin.org/img/icons/logotop.svg",
      },
      contractFun1Id: "",
      contractFun1: "",
    };
  }

  async handleClick() {
    // 0.
    await this.useRegtest();

    // 1.
    await this.createAliceBobWallet();
  }

  // 0.
  // eslint-disable-next-line class-methods-use-this
  async useRegtest() {
    await backend.switch_network(Network.regtest.toString());
    window.localStorage.setItem("network", Network.regtest.toString());
  }

  // 1.
  async createAliceBobWallet() {
    const { ifaceUDA1, ifaceFun1 } = this.state;
    const bitcoin = "bitcoin";
    const server = `${process.env.LOCAL_BITMASK_ENDPOINT}`;

    const usecase = new ExecuteStep("create alice and bob wallets");

    const hash = backend.bitcoin.hashPassword("");

    const aliceWallet = await backend.bitcoin.newWallet(hash, "");
    const alice = await backend.bitcoin.decryptWallet(hash, aliceWallet);
    usecase.ok("Alice wallet created");

    const bobWallet = await backend.bitcoin.newWallet(hash, "");
    const bob = await backend.bitcoin.decryptWallet(hash, bobWallet);
    usecase.ok("Bob wallet created");

    if (alice && bob) {
      const bobSk = bob.private.nostrPrv;
      const aliceSk = alice.private.nostrPrv;
      const aliceKeys = [
        alice.private.rgbDescriptorXprv,
        alice.private.btcDescriptorXprv,
      ];

      await backend.rgb.createWatcher(aliceSk, {
        name: "default",
        xpub: alice.public.btcDescriptorXpub,
        force: false,
      });
      usecase.ok(`Alice watcher created`);

      await backend.rgb.createWatcher(bobSk, {
        name: "default",
        xpub: bob.public.btcDescriptorXpub,
        force: false,
      });
      usecase.ok(`Bob watcher created`);

      let aliceAddr = (await backend.rgb.nextAddress(aliceSk, ifaceFun1))
        .address;
      usecase.ok(`Alice address (${ifaceFun1}): ${aliceAddr}`);

      let aliceUtxo = "";
      if (aliceAddr) {
        await fetch(`${server}/regtest/send/${aliceAddr}/1`);

        aliceUtxo =
          (await backend.rgb.nextUtxo(aliceSk, ifaceFun1))?.utxo || "";
        usecase.ok(`Alice UTXO (${ifaceFun1}): ${aliceUtxo}`);
      }

      aliceAddr = (await backend.rgb.nextAddress(aliceSk, ifaceUDA1)).address;
      usecase.ok(`Alice address (${ifaceUDA1}): ${aliceAddr}`);

      aliceUtxo = "";
      if (aliceAddr) {
        await fetch(`${server}/regtest/send/${aliceAddr}/1`);

        aliceUtxo =
          (await backend.rgb.nextUtxo(aliceSk, ifaceUDA1))?.utxo || "";
        usecase.ok(`Alice UTXO (${ifaceUDA1}): ${aliceUtxo}`);
      }

      let bobAddr = (await backend.rgb.nextAddress(bobSk, ifaceFun1)).address;
      usecase.ok(`Bob address (${ifaceFun1}): ${bobAddr}`);

      let bobRgbFun1Utxo = "";
      if (bobAddr) {
        await fetch(`${server}/regtest/send/${bobAddr}/1`);

        bobRgbFun1Utxo =
          (await backend.rgb.nextUtxo(bobSk, ifaceFun1))?.utxo || "";
        usecase.ok(`Bob UTXO (${ifaceFun1}): ${bobRgbFun1Utxo}`);
      }

      bobAddr = (await backend.rgb.nextAddress(bobSk, ifaceUDA1)).address;
      usecase.ok(`Bob address (${ifaceUDA1}): ${bobAddr}`);

      let bobRgbUDA1Utxo = "";
      if (bobAddr) {
        await fetch(`${server}/regtest/send/${bobAddr}/1`);

        bobRgbUDA1Utxo =
          (await backend.rgb.nextUtxo(bobSk, ifaceUDA1))?.utxo || "";
        usecase.ok(`Bob UTXO (${ifaceUDA1}): ${bobRgbUDA1Utxo}`);
      }

      bobAddr = (await backend.rgb.nextAddress(bobSk, bitcoin)).address;
      usecase.ok(`Bob address (BTC): ${bobAddr}`);

      let bobBtcUtxo = "";
      if (bobAddr) {
        await fetch(`${server}/regtest/send/${bobAddr}/1`);

        bobBtcUtxo = (await backend.rgb.nextUtxo(bobSk, bitcoin))?.utxo || "";
        usecase.ok(`Bob UTXO (BTC): ${bobBtcUtxo}`);
      }

      this.setState(
        {
          alice,
          aliceSk,
          aliceUtxo,
          aliceKeys,
          bob,
          bobSk,
          bobBtcUtxo,
          bobRgbFun1Utxo,
          bobRgbUDA1Utxo,
          steps: [usecase],
        },
        async () => {
          // 2.
          await this.createAliceContract();
        }
      );
    }
  }

  // 2.
  async createAliceContract() {
    const {
      aliceSk,
      aliceUtxo,
      ifaceFun1: iface,
      contractMedia,
      steps,
    } = this.state;

    const usecase = new ExecuteStep("create alice fungible contract");

    const issueReq: IssueRequest = {
      iface,
      ticker: "DIBA",
      name: "DIBA",
      description: "DIBA",
      supply: "5",
      precision: 2,
      seal: `tapret1st:${aliceUtxo}`,
      chain: "bitcoin",
      meta: {
        preview: contractMedia,
        media: contractMedia,
        attachments: [contractMedia],
      },
    };

    const {
      iface: contractIface,
      contractId,
      contract: { armored: contract },
      balance,
    }: IssueResponse = JSON.parse(
      await backend.issue_contract_proxy(aliceSk, issueReq)
    ).data;

    usecase.ok(
      `Alice contract: [${contractIface}] ${contractId} (${balance.value})`
    );

    steps.push(usecase);
    this.setState(
      { contractFun1Id: contractId, contractFun1: contract, steps },
      async () => {
        // 3.
        await this.createAliceSecondContract();
      }
    );
  }

  // 3.
  async createAliceSecondContract() {
    const {
      aliceSk,
      aliceUtxo,
      ifaceUDA1: iface,
      contractMedia,
      steps,
    } = this.state;

    const usecase = new ExecuteStep("create alice uda contract");

    const issueReq: IssueRequest = {
      iface,
      ticker: "DIBB",
      name: "DIBB",
      description: "DIBB",
      supply: "1",
      precision: 0,
      seal: `tapret1st:${aliceUtxo}`,
      chain: "bitcoin",
      meta: {
        preview: contractMedia,
        media: contractMedia,
        attachments: [contractMedia],
      },
    };

    const {
      iface: contractIface,
      contractId,
      contract: { armored: contract },
      balance,
    }: IssueResponse = JSON.parse(
      await backend.issue_contract_proxy(aliceSk, issueReq)
    ).data;

    usecase.ok(
      `Alice contract: [${contractIface}] ${contractId} (${balance.uda})`
    );

    steps.push(usecase);

    this.setState(
      { contractUDA1Id: contractId, contractUDA1: contract, steps },
      async () => {
        // 4.
        await this.createBobUDAInvoice();
      }
    );
  }

  // 4.
  async createBobUDAInvoice() {
    const {
      bobSk,
      bobRgbUDA1Utxo: bobRgbUtxo,
      contractUDA1Id: contractId,
      contractUDA1: contract,
      ifaceUDA1: iface,
      steps,
    } = this.state;
    const usecase = new ExecuteStep("create bob uda invoice");

    await backend.import_contract(bobSk, contract);
    usecase.ok(`Bob imported contract: ${contractId}`);

    const invoiceReq: RgbInvoiceRequest = {
      contractId,
      iface,
      seal: { utxo: `${bobRgbUtxo}` },
      amount: { uda: { tokenIndex: 1, fraction: BigInt(1) } },
    };

    const { invoice }: RgbInvoiceResponse = JSON.parse(
      await backend.create_rgb_invoice(bobSk, invoiceReq)
    ).data;

    usecase.ok(`Bob invoice: ${invoice}`);

    steps.push(usecase);
    this.setState({ steps, bobUDA1Invoice: invoice }, async () => {
      // 5.
      await this.createAliceUDATransfer();
    });
  }

  // 5.
  async createAliceUDATransfer() {
    const server = `${process.env.LOCAL_BITMASK_ENDPOINT}`;
    const {
      aliceSk,
      aliceKeys,
      bobUDA1Invoice: invoice,
      contractUDA1Id: contractId,
      ifaceUDA1: iface,
      steps,
    } = this.state;
    const usecase = new ExecuteStep("create alice uda transfer");

    const transferReq: RgbTransferRequest = {
      contractId,
      iface,
      invoice,
      chain: "bitcoin",
      chainFee: { value: BigInt(1000) },
      bitcoinChanges: [],
    };

    const { consigId }: RgbTransferResponse = JSON.parse(
      await backend.create_and_publish_rgb_transfer(
        aliceSk,
        transferReq,
        aliceKeys
      )
    ).data;

    usecase.ok(`Alice transfer: ${consigId}`);
    await fetch(`${server}/regtest/block`);

    steps.push(usecase);
    this.setState({ steps }, async () => {
      // 6.
      await this.updateAliceUtxos();
    });
  }

  // 6.
  async updateAliceUtxos() {
    const { aliceSk, ifaceFun1: iface, steps } = this.state;
    const usecase = new ExecuteStep("update alice utxos");

    await backend.verify_transfers(aliceSk);
    usecase.ok(`Alice update transfers`);

    const aliceUtxo = (await backend.rgb.nextUtxo(aliceSk, iface))?.utxo || "";
    usecase.ok(`Alice UTXO (${iface}): ${aliceUtxo}`);

    steps.push(usecase);
    this.setState({ steps, aliceUtxo }, async () => {
      // 7.
      await this.createBobFungibleInvoice();
    });
  }

  // 6.
  async createBobFungibleInvoice() {
    const {
      bobSk,
      bobRgbFun1Utxo: bobRgbUtxo,
      contractFun1Id: contractId,
      contractFun1: contract,
      ifaceFun1: iface,
      steps,
    } = this.state;
    const usecase = new ExecuteStep("create bob fungible invoice");

    await backend.import_contract(bobSk, contract);
    usecase.ok(`Bob imported contract: ${contractId}`);

    const invoiceReq: RgbInvoiceRequest = {
      contractId,
      iface,
      seal: { utxo: `${bobRgbUtxo}` },
      amount: { value: "1" },
    };

    const { invoice }: RgbInvoiceResponse = JSON.parse(
      await backend.create_rgb_invoice(bobSk, invoiceReq)
    ).data;

    usecase.ok(`Bob invoice: ${invoice}`);

    steps.push(usecase);
    this.setState({ steps, bobFun1Invoice: invoice }, async () => {
      // 7.
      await this.createAliceFungibleTransfer();
    });
  }

  // 7.
  async createAliceFungibleTransfer() {
    const server = `${process.env.LOCAL_BITMASK_ENDPOINT}`;
    const {
      aliceSk,
      aliceKeys,
      bobFun1Invoice: invoice,
      contractFun1Id: contractId,
      ifaceFun1: iface,
      steps,
    } = this.state;
    const usecase = new ExecuteStep("create alice fungible transfer");

    const transferReq: RgbTransferRequest = {
      contractId,
      iface,
      invoice,
      chain: "bitcoin",
      chainFee: { value: BigInt(1000) },
      bitcoinChanges: [],
    };

    const { consigId }: RgbTransferResponse = JSON.parse(
      await backend.create_and_publish_rgb_transfer(
        aliceSk,
        transferReq,
        aliceKeys
      )
    ).data;

    usecase.ok(`Alice transfer: ${consigId}`);
    await fetch(`${server}/regtest/block`);

    steps.push(usecase);
    this.setState({ steps }, async () => {
      // 8.
      await this.aliceContracts();
    });
  }

  // 8.
  async aliceContracts() {
    const { aliceSk, contractFun1Id, contractUDA1Id, steps } = this.state;
    const usecase = new ExecuteStep("alice contracts state verification");

    await backend.verify_transfers(aliceSk);
    usecase.ok(`Alice update transfers`);

    const {
      iface: iface20,
      balance: balance20,
      allocations: allocations20,
    }: ContractResponse = JSON.parse(
      await backend.get_contract(aliceSk, contractFun1Id)
    );

    usecase.ok(
      `Alice contract: [${iface20}]  ${contractFun1Id} / allocation: ${JSON.stringify(
        balance20.value
      )}`
    );

    let filterAllocations = allocations20.filter((x) => x.isMine && !x.isSpent);
    usecase.ok(`Alice allocations: (${JSON.stringify(filterAllocations)})`);

    const {
      iface: iface21,
      balance: balance21,
      allocations: allocations21,
    }: ContractResponse = JSON.parse(
      await backend.get_contract(aliceSk, contractUDA1Id)
    );

    filterAllocations = allocations21.filter((x) => x.isMine && !x.isSpent);
    usecase.ok(
      `Alice contract: [${iface21}]  ${contractUDA1Id} / allocation: ${JSON.stringify(
        balance21.uda
      )}`
    );

    usecase.ok(`Alice allocations: (${JSON.stringify(filterAllocations)})`);

    steps.push(usecase);
    this.setState({ steps }, async () => {
      // 9.
      await this.bobContracts();
    });
  }

  // 9.
  async bobContracts() {
    const { bobSk, contractFun1Id, contractUDA1Id, steps } = this.state;
    const usecase = new ExecuteStep("bob contracts state verification");

    await backend.verify_transfers(bobSk);
    usecase.ok(`Bob update transfers`);

    const {
      iface: iface20,
      balance: balance20,
      allocations: allocations20,
    }: ContractResponse = JSON.parse(
      await backend.get_contract(bobSk, contractFun1Id)
    );

    usecase.ok(
      `Bob contract: [${iface20}]  ${contractFun1Id} / allocation: ${JSON.stringify(
        balance20.value
      )}`
    );

    let filterAllocations = allocations20.filter((x) => x.isMine && !x.isSpent);
    usecase.ok(`Bob allocations: (${JSON.stringify(filterAllocations)})`);

    const {
      iface: iface21,
      balance: balance21,
      allocations: allocations21,
    }: ContractResponse = JSON.parse(
      await backend.get_contract(bobSk, contractUDA1Id)
    );

    filterAllocations = allocations21.filter((x) => x.isMine && !x.isSpent);
    usecase.ok(
      `Bob contract: [${iface21}]  ${contractUDA1Id} / allocation: ${JSON.stringify(
        balance21.uda
      )}`
    );

    usecase.ok(`Bob allocations: (${JSON.stringify(filterAllocations)})`);

    steps.push(usecase);
    this.setState({ steps });
  }

  render() {
    const { steps } = this.state;
    const { value } = this.props;
    return (
      <>
        <div className="shadow-lg cursor-pointer dark:bg-newdarkmode-800 dark:border-1/2 dark:border-newdarkmode-600 dark:border-opacity-25 rounded-xl divide-y-1/2 divide-newdarkmode-600">
          <div
            className="flex items-center my-auto sm:justify-between sm:w-full"
            onClick={async () => this.handleClick()}
            onKeyDown={async () => this.handleClick()}
            role="presentation"
          >
            <div className="relative flex py-2 pl-6 cursor-pointer xs:py-4 md:pl-9 lg:px-6 focus:outline-none">
              <p className="pr-4 my-auto text-base text-left text-yellow-500 xl:text-lg">
                {value}
              </p>
            </div>
          </div>
        </div>
        {steps.map((step: ExecuteStep, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Step key={index} usecase={step.usecase} result={step.result} />
        ))}
      </>
    );
  }
}

export default Rgb2Utxo;
