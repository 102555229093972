/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import PageWrapper2 from "src/Components/Layout/Wrappers/PageWrapper2";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Copy from "src/Components/Icons/Copy";
import { classNames } from "src/Hooks/util";
import { Location } from "src/types";
import TextField from "src/Components/Inputs/TextField";
import { bitcoin } from "bitmask-core";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import LightningNetwork from "src/Components/Icons/LightningNetwork";
import Bitcoin from "src/Components/Icons/Bitcoin";

const ViewTransaction = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { transaction, walletData, vault, conversion, hash, lnCredentials } =
    location.state;
  const network = window.localStorage.getItem("network");
  const [loading, setLoading] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [feeRate, setFeeRate] = useState(transaction.feeRate);
  const [fees, setFees] = useState(0);

  useEffect(() => {
    (async () => {
      if (!transaction.confirmed) {
        const tx = await bitcoin.bumpFee(
          transaction.id,
          transaction.feeRate,
          false,
          vault.private.btcDescriptorXprv,
          vault.private.btcChangeDescriptorXprv
        );
        setFees(tx.details.fee);
      }
    })();
  }, []);

  const handleFeeRate = async (newFeeRate: number) => {
    setFeeRate(newFeeRate);
    setFees(0);
    const tx = await bitcoin.bumpFee(
      transaction.id,
      newFeeRate,
      false,
      vault.private.btcDescriptorXprv,
      vault.private.btcChangeDescriptorXprv
    );
    setFees(tx.details.fee);
  };

  const handleFeeBump = async () => {
    try {
      setLoading(true);
      const tx = await bitcoin.bumpFee(
        transaction.id,
        feeRate,
        true,
        vault.private.btcDescriptorXprv,
        vault.private.btcChangeDescriptorXprv
      );

      navigate("/success", {
        state: {
          wallet: walletData.name,
          vault,
          hash,
          transaction: {
            ...transaction,
            details: tx.details,
            id: tx.details.txid,
          },
          lnCredentials,
        },
      });
    } catch (err) {
      setLoading(false);
      console.error(err);
      // TODO: Show error dialog
    }
  };

  return (
    <PageWrapper2
      className=""
      title="Transaction details"
      handlePageBack={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, lnCredentials, hash },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, lnCredentials, hash },
        })
      }
    >
      <div className="flex flex-col w-auto">
        <div className="w-full p-6 m-auto mt-6 text-center border-1 dark:border-darkmode-700 rounded-xl">
          <p className="my-auto text-base sm:text-lg font-thin text-gray-800 truncate dark:text-gray-500 mb-1.5">
            {transaction.action === "sent" ? "Sent" : "Received"}{" "}
          </p>
          <p className="mb-3 text-2xl font-semibold text-black dark:text-gray-300">
            {transaction.assetType !== "UDA" &&
              transaction.amount.toLocaleString()}{" "}
            {network !== "bitcoin" ? "tSats" : "Sats"}{" "}
          </p>
          {["sats", "Lightning"].includes(transaction.assetType) && (
            <div className="flex flex-row justify-center flex-nowrap gap-3">
              <p className="text-base font-thin text-gray-800 dark:text-gray-500">
                {Number(transaction.amount / 100_000_000).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 8,
                  }
                )}{" "}
                BTC
              </p>
              <p className="text-base font-thin text-gray-800 dark:text-gray-500">
                {Number(
                  (Number(transaction.amount) / 100_000_000) * conversion
                ).toLocaleString(undefined, { minimumFractionDigits: 3 })}{" "}
                USD
              </p>
            </div>
          )}
        </div>
        {!transaction.lightning && !transaction.confirmed && (
          <div className="flex flex-col w-full m-auto mt-6 text-center border-1 dark:border-darkmode-700 rounded-xl">
            <p className="px-6 mt-2 text-sm font-thin text-left text-gray-900 lg:mt-4 sm:text-sm lg:text-base dark:text-gray-500">
              Use RBF swap out an unconfirmed Bitcoin transaction for a new one
              with a higher transaction fee.
            </p>
            <div className="justify-center px-6 pb-6 mt-3 font-thin grid grid-cols-2 border-t-1 dark:border-darkmode-700">
              <p className="my-auto text-left">Target fee rate:</p>
              <div className="ml-auto">
                <TextField
                  className="w-full p-3 ml-auto"
                  placeholder="Fee rate"
                  type="number"
                  onChange={(e) => handleFeeRate(e.target.value)}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? handleFeeRate(e.target.value) : null
                  }
                  value={feeRate}
                />
              </div>

              <div className="my-auto text-left">
                Fees: {fees === 0 ? "---" : fees}
              </div>
              <RoundedButton
                className="w-auto px-6 py-2 my-auto ml-auto text-sm text-black bg-yellow-500 sm:text-base"
                onClick={() => handleFeeBump()}
                loading={loading}
              >
                Bump Fee
              </RoundedButton>
            </div>
          </div>
        )}
        {!transaction.lightning && (
          <div
            className="flex w-full py-2 m-auto text-base font-thin text-center text-gray-900 truncate sm:text-lg sm:py-4 flex-nowrap dark:text-gray-500 border-b-1 dark:border-darkmode-700"
            title="View transaction on Mempool"
          >
            <p className="my-auto">Transaction</p>
            <a
              id="mempoolLink"
              href={`${
                network === "signet"
                  ? "https://mutinynet.com/tx/"
                  : `https://diba.mempool.space/${
                      network !== "bitcoin" ? "testnet/" : ""
                    }tx/`
              }${transaction.id}`}
              target="_blank"
              rel="noreferrer"
              className="flex w-auto pl-3 my-auto ml-auto font-normal text-right text-gray-800 dark:text-yellow-500 flex-nowrap"
            >
              <p className="truncate max-w-40 xs:max-w-44 sm:max-w-64">
                {transaction.id}
              </p>
              <ArrowUpRightIcon className="w-4 h-4 my-auto font-bold sm:ml-1" />
            </a>
            <button
              className="my-auto ml-3"
              type="button"
              aria-label="Copy transaction ID"
              onClick={() => {
                navigator.clipboard.writeText(transaction.id || "");
                setOpenCopyModal(true);
              }}
            >
              <Copy className="w-6 h-6 text-yellow-500" />
            </button>
          </div>
        )}
        <div
          className="flex w-full py-2 m-auto text-base font-thin text-center text-gray-900 truncate sm:text-lg sm:py-4 flex-nowrap dark:text-gray-500 border-b-1 dark:border-darkmode-700"
          title="View transaction on Mempool"
        >
          {" "}
          <p className="my-auto">Network</p>
          {!transaction.lightning ? (
            <div className="flex ml-auto flex-nowrap">
              <Bitcoin className="w-6 h-6 my-auto mr-1.5" />
              <p className="font-thin text-right text-gray-500">Bitcoin (L1)</p>
            </div>
          ) : (
            <div className="flex ml-auto flex-nowrap">
              <LightningNetwork className="w-6 h-6 my-auto mr-1.5" />
              <p className="font-thin text-right text-gray-500">
                Lightning (L2)
              </p>
            </div>
          )}
        </div>

        <div className="flex pb-3 mb-4 sm:pb-6 sm:mb-6">
          <div className="flex-col w-full h-auto text-left">
            {Object.entries(transaction)
              .filter(
                (elem) => !["txid", "action", "status", "id"].includes(elem[0])
              )
              .map(([key, value]) => (
                <div
                  key={key}
                  className="w-full py-2 text-base font-thin border-b-1 dark:border-darkmode-700 sm:py-4 last:border-0 sm:text-lg"
                >
                  <div className={classNames("flex flex-row w-full")}>
                    <p className="my-auto font-thin text-left text-black dark:text-gray-500">
                      {key === "confirmationTime"
                        ? "confirmation time"
                        : JSON.stringify(key).replace(/['"]+/g, "")}
                    </p>
                    {key === "date" ? (
                      <p className="w-auto ml-auto text-right text-black truncate dark:text-gray-500">
                        {new Date(value * 1000).toLocaleDateString(undefined, {
                          hour12: true,
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </p>
                    ) : key === "confirmationTime" && value !== null ? (
                      <div className="py-1.5 ml-auto">
                        {Object.entries(value).map(([k, val]) => (
                          <div key={k} className="flex flex-col">
                            <div className="ml-4 text-black truncate dark:text-gray-500 max-w-80 xs:max-w-96 sm:max-w-full sm:w-auto">
                              <span className="dark:text-gray-500">{k}</span>
                              <span className="ml-auto text-right dark:text-gray-500">
                                {" "}
                                {val as unknown as string}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="ml-auto text-black truncate dark:text-gray-500 max-w-80 xs:max-w-96 sm:max-w-full sm:w-auto">
                        {JSON.stringify(
                          value === null
                            ? "pending confirmation"
                            : typeof value === "number"
                            ? value.toLocaleString()
                            : value
                        )
                          .replace(/['"]+/g, "")
                          .replace(/[{}]+/g, "")}
                      </p>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
    </PageWrapper2>
  );
};

export default ViewTransaction;
