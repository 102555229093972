import React from "react";
import { Disclosure } from "@headlessui/react";
import Plus from "../Icons/Plus";
import Minus from "../Icons/Minus";

const Step = ({ ...props }) => (
  <div className="flex flex-col w-full mx-auto bggradientlanding rounded-2xl divide-y-1/2 divide-gray-500 divide-opacity-25">
    <Disclosure key={props.usecase}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`flex flex-nowrap flex-shrink-0 w-full px-4 py-6 lg:py-8 text-lg text-left text-gray-200 ease-in-out `}
          >
            {open ? (
              <Minus className="my-auto mr-4 min-w-8 min-h-8 max-w-8 max-h-8" />
            ) : (
              <Plus className="my-auto mr-4 min-w-8 min-h-8 max-w-8 max-h-8" />
            )}
            <span className="my-auto ml-1.5 text-base font-light xl:text-xl font-integral">
              {props.usecase}
            </span>
          </Disclosure.Button>

          {props.result.map((result) => (
            <Disclosure.Panel
              key={result}
              className="max-w-3xl px-6 py-6 text-base font-light text-gray-300 md:ml-12 xl:text-lg leading-9 lg:px-0 lg:max-w-4xl"
            >
              {result}
            </Disclosure.Panel>
          ))}
        </>
      )}
    </Disclosure>
  </div>
);

export default Step;
