/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* global chrome */
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import * as backend from "bitmask-core";
import ErrorModal from "src/Components/Modals/Error";
import InfoModal from "src/Components/Modals/Info";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import RoundedTabs from "src/Components/Tabs/RoundedTabs";
import { Location } from "src/types";
import PageWrapper2 from "src/Components/Layout/Wrappers/PageWrapper2";
import {
  removeInvoice,
  RgbInvoiceStatusResponse,
  RgbTransferItem,
} from "bitmask-core/rgb";
import Copy from "src/Components/Icons/Copy";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

type Category = {
  date: string;
  dateTime: string;
  transactions: RgbInvoiceStatusResponse[] | RgbTransferItem[];
};

const categorizeActivity = (
  transactions: RgbInvoiceStatusResponse[] | RgbTransferItem[]
): Category[] => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const past7Days = new Date(today);
  past7Days.setDate(today.getDate() - 7);

  const isSameDay = (date1: Date, date2: Date) =>
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear();

  const categories: { [key: string]: Category } = {
    Today: {
      date: "Today",
      dateTime: today.toISOString().split("T")[0],
      transactions: [],
    },
    Yesterday: {
      date: "Yesterday",
      dateTime: yesterday.toISOString().split("T")[0],
      transactions: [],
    },
    Past7Days: {
      date: "Past 7 Days",
      dateTime: past7Days.toISOString().split("T")[0],
      transactions: [],
    },
    OverAWeekAgo: {
      date: "Over a Week Ago",
      dateTime: "",
      transactions: [],
    },
  };

  transactions.forEach((transaction) => {
    let transactionDate = new Date();
    transactionDate = new Date(transactionDate);
    if (isSameDay(transactionDate, today)) {
      categories.Today.transactions.push(transaction);
    } else if (isSameDay(transactionDate, yesterday)) {
      categories.Yesterday.transactions.push(transaction);
    } else if (transactionDate > past7Days) {
      categories.Past7Days.transactions.push(transaction);
    } else {
      categories.OverAWeekAgo.transactions.push(transaction);
    }
  });

  return [
    categories.Today,
    categories.Yesterday,
    categories.Past7Days,
    categories.OverAWeekAgo,
  ].filter((frame) => frame.transactions.length > 0);
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ManageInvoicesAndTransfers = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { vault, hash, walletData, lnCredentials, invoices, transfers } =
    location?.state ?? {};
  const [openError, setOpenError] = useState(false);
  const [error, _setError] = useState({
    title: "",
    message: "",
  });
  const [openInfo, setOpenInfo] = useState(false);
  const [info, setInfo] = useState({
    title: "",
    message: "",
  });
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const options = [
    {
      name: "Invoices",
      current: true,
    },
    {
      name: "Transfers",
      current: false,
    },
  ];
  const [selected, setSelected] = useState(options[0]);
  const categorizedInvoices = categorizeActivity(invoices || []);
  const categorizedTransfers = categorizeActivity(transfers || []);

  return (
    <PageWrapper2
      className="h-auto"
      title="Manage Invoice and Transfers"
      handlePageBack={() => navigate("/")}
    >
      <div className="flex justify-center w-full h-auto mx-auto my-6 overflow-x-hidden overflow-y-auto darkscrollbar min-h-page">
        <div className="w-full max-w-4xl">
          <div className="">
            <RoundedTabs
              tabs={options}
              setSelected={setSelected}
              selected={selected}
            />
          </div>
          {selected.name === "Invoices" && (
            <div className="h-auto overflow-hidden">
              <div className="h-full">
                {categorizedInvoices.length > 0 ? (
                  <>
                    <h2 className="my-3 text-left font-thin text-lg text-white">
                      Manage open invoices
                    </h2>
                    <table className="w-full text-left">
                      <thead className="sr-only">
                        <tr>
                          <th>Ticker and name</th>
                          <th className="hidden sm:table-cell">
                            Invoice amount
                          </th>
                          <th>Invoice options and or status</th>
                        </tr>
                      </thead>
                      {categorizedInvoices.map((day) => (
                        <tbody className="w-full" key={day.dateTime}>
                          <tr className="text-sm leading-6 text-gray-300">
                            <th
                              scope="colgroup"
                              colSpan={3}
                              className="relative p-2 font-normal isolate"
                            >
                              <time dateTime={day.dateTime}>{day.date}</time>
                              <div className="absolute inset-y-0 w-screen right-full -z-10 bg-darkmode-800" />
                              <div className="absolute inset-y-0 left-0 w-screen -z-10 bg-darkmode-800" />
                            </th>
                          </tr>
                          {day.transactions.map((transaction) => (
                            <tr
                              className="w-full"
                              key={String(transaction.invoice)}
                            >
                              <td className="py-5 pr-6 table-cell text-left">
                                <div className="text-sm leading-6 text-white ml-2">
                                  {transaction?.assetTicker || "TICKER"}
                                </div>
                                <div className="mt-1 text-xs leading-5 text-gray-500 ml-2">
                                  {transaction.assetName || "token name"}
                                </div>
                              </td>
                              <td className="hidden py-5 pr-6 sm:table-cell">
                                <div className="text-sm leading-6 text-gray-300">
                                  {`invoice created to receive ${
                                    transaction?.amount?.value || ""
                                  } ${transaction?.assetTicker || "TICKER"}`}
                                </div>
                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  {new Date().toDateString()}
                                </div>
                              </td>
                              <td className="py-5 text-right mr-0 ml-auto">
                                <div className="flex my-auto mr-0 ml-auto">
                                  {transaction?.status === "payed" ? (
                                    <div className="flex flex-row flex-nowrap gap-3 my-auto mr-0 ml-auto">
                                      <span className="text-sm leading-6 text-gray-300 my-auto">
                                        Payed
                                      </span>
                                      <CheckCircleIcon
                                        className="w-8 h-8 text-green-600 dark:text-white-500"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  ) : (
                                    <div className="flex flex-row flex-nowrap gap-3 my-auto mr-0 ml-auto">
                                      <RoundedButton
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            String(transaction.invoice)
                                          );
                                          setOpenCopyModal(true);
                                        }}
                                        className="p-2 bg-darkmode-700 ml-auto"
                                      >
                                        Copy
                                        <p className="hidden xs:inline-block sm:hidden md:inline-block ml-1">
                                          Invoice
                                        </p>
                                        <Copy className="h-5 w-5 my-auto ml-1" />
                                      </RoundedButton>
                                      <RoundedButton
                                        onClick={async () => {
                                          await removeInvoice(
                                            vault.private.nostrPrv,
                                            transaction.invoice
                                          );
                                          setInfo({
                                            title: "Remove Invoice sucess",
                                            message: `the invoice ${
                                              transaction.assetTicker
                                            } (${
                                              transaction?.amount?.value || ""
                                            }) has been successfully removed.`,
                                          });
                                          setOpenInfo(true);
                                        }}
                                        className="p-2 bg-red-800 ml-auto"
                                      >
                                        Cancel
                                        <p className="hidden xs:inline-block sm:hidden md:inline-block ml-1">
                                          Invoice
                                        </p>
                                      </RoundedButton>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ))}
                    </table>
                  </>
                ) : (
                  <div className="flex justify-center pt-12 m-auto text-lg font-thin text-center text-gray-500">
                    No open invoices
                  </div>
                )}
              </div>
            </div>
          )}
          {selected.name === "Transfers" && (
            <div className="overflow-hidden h-auto">
              <div className="h-full">
                {categorizedTransfers.length > 0 ? (
                  <>
                    <h2 className="my-3 text-left font-thin text-lg text-white">
                      Manage transfers
                    </h2>
                    <table className="w-full text-left">
                      <thead className="sr-only">
                        <tr>
                          <th>Ticker and name</th>
                          <th className="hidden sm:table-cell">
                            consignment info
                          </th>
                          <th>consignment</th>
                        </tr>
                      </thead>
                      {categorizedTransfers.map((day) => (
                        <tbody className="w-full" key={day.dateTime}>
                          <tr className="text-sm leading-6 text-gray-300">
                            <th
                              scope="colgroup"
                              colSpan={3}
                              className="relative p-2 font-normal isolate"
                            >
                              <time dateTime={day.dateTime}>{day.date}</time>
                              <div className="absolute inset-y-0 w-screen right-full -z-10 bg-darkmode-800" />
                              <div className="absolute inset-y-0 left-0 w-screen -z-10 bg-darkmode-800" />
                            </th>
                          </tr>
                          {day.transactions.map((transaction) => (
                            <tr
                              className="w-full flex-grow-default justify-between flex"
                              key={String(transaction.transferId)}
                            >
                              <td className="py-5 pr-6 table-cell text-left">
                                <div className="text-sm leading-6 text-white ml-2">
                                  {transaction?.assetTicker || "TICKER"}
                                </div>
                                <div className="mt-1 text-xs leading-5 text-gray-500 ml-2">
                                  {transaction?.assetName || "token name"}
                                </div>
                              </td>
                              <td className="hidden py-5 pr-6 sm:table-cell">
                                <div className="text-sm leading-6 text-gray-300">
                                  {transaction?.assetAmount > 0
                                    ? `Consignment created to send ${
                                        transaction?.assetAmount
                                      } ${transaction?.assetTicker || "TICKER"}`
                                    : `Consignment created`}
                                </div>
                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  {new Date().toDateString()}
                                </div>
                              </td>
                              <td className="py-5 text-right">
                                <RoundedButton
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      String(transaction.consignment)
                                    );
                                    setOpenCopyModal(true);
                                  }}
                                  className="p-2 bg-darkmode-700 ml-auto mr-2"
                                >
                                  Copy Consignment
                                  <Copy className="h-5 w-5 my-auto ml-1" />
                                </RoundedButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ))}
                    </table>
                  </>
                ) : (
                  <div className="flex justify-center pt-12 m-auto text-lg font-thin text-center text-gray-500">
                    No open transfers
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
        <ErrorModal
          open={openError}
          setOpen={setOpenError}
          message={error.message}
          title={error.title}
        />
        <InfoModal
          open={openInfo}
          setOpen={setOpenInfo}
          message={info.message}
          title={info.title}
          onClose={() => {
            navigate("/wallet", {
              state: {
                wallet: walletData.name,
                vault,
                hash,
                lnCredentials,
              },
            });
          }}
        />
      </div>
    </PageWrapper2>
  );
};

export default ManageInvoicesAndTransfers;
