/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */

import * as backend from "bitmask-core";
import { Vault } from "bitmask-core/bitcoin";
import {
  ContractResponse,
  IssueRequest,
  IssueResponse,
  MediaData,
  RgbAuctionCloseRequest,
  RgbBidRequest,
  RgbBidResponse,
  RgbOfferRequest,
  RgbOfferResponse,
  RgbSwap,
} from "bitmask-core/rgb";
import { Network } from "bitmask-core/constants";

import React from "react";

import Step from "./Step";
import ExecuteStep from "./ExecuteStep";

interface RgbState {
  steps: ExecuteStep[];

  alice: Vault | undefined;
  aliceSk: string;
  aliceUtxo: string;
  aliceKeys: string[];
  aliceOfferId: string;
  aliceOfferSk: string;
  aliceOfferBundle: string;
  aliceOfferAmount: string;
  aliceOfferPrice: bigint;

  bob: Vault | undefined;
  bobSk: string;
  bobKeys: string[];
  bobBtcUtxo: string;
  bobRgbUtxo: string;
  bobRgbInvoice: string;
  bobBidId: string;
  bobBidSk: string;

  iface: string;
  contract: string;
  contractId: string;
  contractMedia: MediaData;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class RgbAuction extends React.Component<{ value: string }, RgbState> {
  constructor(props) {
    super(props);

    this.state = {
      steps: [],

      alice: undefined,
      aliceSk: "",
      aliceUtxo: "",
      aliceKeys: [],
      aliceOfferId: "",
      aliceOfferSk: "",
      aliceOfferBundle: "",
      aliceOfferAmount: "",
      aliceOfferPrice: BigInt(0),

      bob: undefined,
      bobSk: "",
      bobKeys: [],
      bobBtcUtxo: "",
      bobRgbUtxo: "",
      bobRgbInvoice: "",
      bobBidId: "",
      bobBidSk: "",

      iface: "",
      contract: "",
      contractId: "",
      contractMedia: {
        type: "image/svg+xml",
        uri: "https://bitcoin.org/img/icons/logotop.svg",
      },
    };
  }

  async handleClick() {
    // 0.
    await this.useRegtest();

    // 1.
    await this.createAliceBobWallet();
  }

  // 0.
  // eslint-disable-next-line class-methods-use-this
  async useRegtest() {
    await backend.switch_network(Network.regtest.toString());

    window.localStorage.setItem("network", Network.regtest.toString());
  }

  // 1.
  async createAliceBobWallet() {
    const iface = "RGB21";
    const bitcoin = "bitcoin";
    const server = `${process.env.LOCAL_BITMASK_ENDPOINT}`;

    const usecase = new ExecuteStep("create alice and bob wallets");

    const hash = backend.bitcoin.hashPassword("");

    const aliceWallet = await backend.bitcoin.newWallet(hash, "");
    const alice = await backend.bitcoin.decryptWallet(hash, aliceWallet);
    usecase.ok("Alice wallet created");

    const bobWallet = await backend.bitcoin.newWallet(hash, "");
    const bob = await backend.bitcoin.decryptWallet(hash, bobWallet);
    usecase.ok("Bob wallet created");

    if (alice && bob) {
      const bobSk = bob.private.nostrPrv;
      const bobKeys = [
        bob.private.rgbDescriptorXprv,
        bob.private.btcDescriptorXprv,
      ];
      const aliceSk = alice.private.nostrPrv;
      const aliceKeys = [
        alice.private.rgbDescriptorXprv,
        alice.private.btcDescriptorXprv,
      ];

      await backend.rgb.createWatcher(aliceSk, {
        name: "default",
        xpub: alice.public.btcDescriptorXpub,
        force: false,
      });
      usecase.ok(`Alice watcher created`);

      await backend.rgb.createWatcher(bobSk, {
        name: "default",
        xpub: bob.public.btcDescriptorXpub,
        force: false,
      });
      usecase.ok(`Bob watcher created`);

      const aliceAddr = (await backend.rgb.nextAddress(aliceSk, iface)).address;
      usecase.ok(`Alice address (RGB): ${aliceAddr}`);

      let aliceUtxo = "";
      if (aliceAddr) {
        await fetch(`${server}/regtest/send/${aliceAddr}/1`);

        aliceUtxo = (await backend.rgb.nextUtxo(aliceSk, iface))?.utxo || "";
        usecase.ok(`Alice UTXO (RGB): ${aliceUtxo}`);
      }

      let bobAddr = (await backend.rgb.nextAddress(bobSk, iface)).address;
      usecase.ok(`Bob address (RGB): ${bobAddr}`);

      let bobRgbUtxo = "";
      if (bobAddr) {
        await fetch(`${server}/regtest/send/${bobAddr}/1`);

        bobRgbUtxo = (await backend.rgb.nextUtxo(bobSk, iface))?.utxo || "";
        usecase.ok(`Bob UTXO (RGB): ${bobRgbUtxo}`);
      }

      bobAddr = (await backend.rgb.nextAddress(bobSk, bitcoin)).address;
      usecase.ok(`Bob address (BTC): ${bobAddr}`);

      let bobBtcUtxo = "";
      if (bobAddr) {
        await fetch(`${server}/regtest/send/${bobAddr}/1`);

        bobBtcUtxo = (await backend.rgb.nextUtxo(bobSk, bitcoin))?.utxo || "";
        usecase.ok(`Bob UTXO (BTC): ${bobBtcUtxo}`);
      }

      this.setState(
        {
          alice,
          aliceSk,
          aliceUtxo,
          aliceKeys,
          bob,
          bobSk,
          bobKeys,
          bobBtcUtxo,
          bobRgbUtxo,
          iface,
          steps: [usecase],
        },
        async () => {
          // 2.
          await this.createAliceContract();
        }
      );
    }
  }

  // 2.
  async createAliceContract() {
    const { aliceSk, aliceUtxo, iface, contractMedia, steps } = this.state;

    const usecase = new ExecuteStep("create alice contract");

    const issueReq: IssueRequest = {
      iface,
      ticker: "DIBA",
      name: "DIBA",
      description: "DIBA",
      supply: "1",
      precision: 0,
      seal: `tapret1st:${aliceUtxo}`,
      chain: "bitcoin",
      meta: {
        preview: contractMedia,
        media: contractMedia,
        attachments: [contractMedia],
      },
    };

    const {
      iface: contractIface,
      contractId,
      contract: { armored: contract },
      balance,
    }: IssueResponse = JSON.parse(
      await backend.issue_contract_proxy(aliceSk, issueReq)
    ).data;

    usecase.ok(
      `Alice contract: [${contractIface}] ${contractId} (${balance.uda})`
    );

    steps.push(usecase);
    this.setState({ contractId, contract, steps }, async () => {
      // 3.
      await this.createAliceOffer();
    });
  }

  // 3.
  async createAliceOffer() {
    const { aliceSk, aliceKeys, contractId, iface, steps } = this.state;
    const usecase = new ExecuteStep("create alice auction");

    const assetAmount = "1";
    const bitcoinPrice = BigInt(1000);
    const offerReq: RgbOfferRequest = {
      strategy: RgbSwap.auction,
      offers: [{ contractId, iface, assetAmount, bitcoinPrice }],
    };

    const offer = JSON.parse(
      await backend.create_auction_offer(aliceSk, offerReq, aliceKeys)
    ).data;

    const {
      offerId: aliceOfferId,
      sharedKey: aliceOfferSk,
      bitcoinPrice: aliceOfferPrice,
      bundleId: aliceOfferBundle,
    }: RgbOfferResponse = offer[0];
    usecase.ok(`Alice auction created: ${aliceOfferId} (${aliceOfferPrice})`);

    steps.push(usecase);
    this.setState(
      {
        aliceOfferId,
        aliceOfferSk,
        aliceOfferPrice,
        aliceOfferAmount: assetAmount,
        aliceOfferBundle: aliceOfferBundle || "",
        steps,
      },
      async () => {
        // 4.
        await this.createBobBid();
      }
    );
  }

  // 4.
  async createBobBid() {
    const {
      bobSk,
      bobKeys,
      aliceOfferId,
      aliceOfferAmount,
      aliceOfferPrice,
      aliceOfferBundle,
      contractId,
      contract,
      steps,
    } = this.state;
    const usecase = new ExecuteStep("create bob bid");

    await backend.import_contract(bobSk, contract);
    usecase.ok(`Bob imported contract: ${contractId}`);

    const chainFee = { value: BigInt(1000) };
    const bidReq: RgbBidRequest = {
      offerId: aliceOfferId,
      bundleId: aliceOfferBundle,
      assetAmount: aliceOfferAmount,
      bitcoinPrice: aliceOfferPrice,
      fee: chainFee,
    };

    const { bidId }: RgbBidResponse = JSON.parse(
      await backend.create_auction_bid(bobSk, bidReq, bobKeys)
    ).data;

    usecase.ok(`Bob bid created: ${bidId} (${aliceOfferPrice})`);

    steps.push(usecase);
    this.setState({ bobBidId: bidId, steps }, async () => {
      // 5.
      await this.closeAliceAuction();
    });
  }

  // 5.
  async closeAliceAuction() {
    const { aliceSk, aliceOfferBundle: bundleId, steps } = this.state;
    const usecase = new ExecuteStep("close alice auction");

    const closeReq: RgbAuctionCloseRequest = { bundleId };
    await backend.close_auction_offer(aliceSk, closeReq);

    usecase.ok(`Alice closed auction: ${bundleId}`);

    steps.push(usecase);
    this.setState({ steps }, async () => {
      // 6.
      await this.aliceContract();
    });
  }

  // 6.
  async aliceContract() {
    const { aliceSk, contractId, steps } = this.state;
    const usecase = new ExecuteStep("alice contract state verification");

    await backend.verify_transfers(aliceSk);
    usecase.ok(`Alice update transfers`);

    const { iface, balance, allocations }: ContractResponse = JSON.parse(
      await backend.get_contract(aliceSk, contractId)
    );

    usecase.ok(
      `Alice contract: [${iface}]  ${contractId} / allocation: ${JSON.stringify(
        balance.uda
      )}`
    );

    const filterAllocations = allocations.filter((x) => x.isMine && !x.isSpent);
    usecase.ok(`Alice allocations: (${JSON.stringify(filterAllocations)})`);

    steps.push(usecase);
    this.setState({ steps }, async () => {
      // 6.
      await this.bobContract();
    });
  }

  // 6.
  async bobContract() {
    const { bobSk, contractId, steps } = this.state;
    const usecase = new ExecuteStep("bob contract state verification");

    await backend.verify_transfers(bobSk);
    usecase.ok(`Bob update transfers`);

    const { iface, balance, allocations }: ContractResponse = JSON.parse(
      await backend.get_contract(bobSk, contractId)
    );

    usecase.ok(
      `Bob contract: [${iface}] ${contractId} / allocation: ${JSON.stringify(
        balance.uda
      )}`
    );

    const filterAllocations = allocations.filter((x) => x.isMine && !x.isSpent);
    usecase.ok(`Bob allocations: (${JSON.stringify(filterAllocations)})`);

    steps.push(usecase);
    this.setState({ steps });
  }

  render() {
    const { steps } = this.state;
    const { value } = this.props;
    return (
      <>
        <div className="shadow-lg cursor-pointer dark:bg-newdarkmode-800 dark:border-1/2 dark:border-newdarkmode-600 dark:border-opacity-25 rounded-xl divide-y-1/2 divide-newdarkmode-600">
          <div
            className="flex items-center my-auto sm:justify-between sm:w-full"
            onClick={async () => this.handleClick()}
            onKeyDown={async () => this.handleClick()}
            role="presentation"
          >
            <div className="relative flex py-2 pl-6 cursor-pointer xs:py-4 md:pl-9 lg:px-6 focus:outline-none">
              <p className="pr-4 my-auto text-base text-left text-yellow-500 xl:text-lg">
                {value}
              </p>
            </div>
          </div>
        </div>
        {steps.map((step: ExecuteStep, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Step key={index} usecase={step.usecase} result={step.result} />
        ))}
      </>
    );
  }
}

export default RgbAuction;
