import React from "react";
import { classNames } from "src/Hooks/util";

const TextFieldWithButton = ({ className, handleOnCLick, ...props }) => (
  <div className={classNames(`${className} "w-full`)}>
    {props.label && (
      <p className="mb-1 text-sm font-light text-left text-gray-800 dark:text-gray-500">
        {props.label}
      </p>
    )}
    <div className="flex w-full mt-2 rounded-lg flex-grow-default">
      <div className="relative flex items-stretch flex-grow-default focus-within:z-10">
        <input
          type={props.type}
          className="block w-full p-3 mx-auto text-base placeholder-gray-600 bg-gray-200 border-none  without-ring focus:bg-gray-300 rounded-l-xl focus:ring-darkmode-600 dark:bg-darkmode-700 focus:ring-1 disabled:opacity-50 disabled:cursor-not-allowed focus:text-gray-900 dark:placeholder-gray-600 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-600"
          placeholder={props.placeholder}
          onChange={(e) => props.handleOnInputChange(e.target.value)}
          value={props.inputValue}
          {...props}
          disabled={false}
        />
      </div>
      <button
        type="button"
        className={classNames(
          props.warning ? "dark:text-red-600" : "dark:text-yellow-500",
          "relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-xl px-3 py-2 text-sm font-medium dark:bg-darkmode-700 text-gray-900 without-ring hover:bg-gray-50 dark:disabled:text-opacity-50 disabled:cursor-not-allowed"
        )}
        onClick={() => handleOnCLick()}
        disabled={props.disabled}
      >
        {props.buttonName}
      </button>
    </div>
  </div>
);

export default TextFieldWithButton;
