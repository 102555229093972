/* global chrome */
import { StorageKey, StorageResult } from "src/types";

export const storageSet = (query) =>
  new Promise<void>((resolve) => {
    chrome.storage.local.set(query, () => {
      resolve();
    });
  });

export const storageGet = <T>(keys: StorageKey): Promise<StorageResult<T>> =>
  new Promise((resolve) => {
    chrome.storage.local.get(keys, (result) => {
      resolve(result);
    });
  });

export const storageDel = (keys) =>
  new Promise<void>((resolve) => {
    chrome.storage.local.remove(keys, () => {
      resolve();
    });
  });

export const storageClear = () =>
  new Promise<void>((resolve) => {
    chrome.storage.local.clear(() => {
      resolve();
    });
  });
