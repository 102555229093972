import React, { useRef, useEffect } from "react";

import { classNames, omitKeys } from "src/Hooks/util";

const TextField = ({ className = "", ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.focus) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <div className="flex flex-col justify-center mx-auto my-4">
      {props.label && (
        <p className="mb-1 text-sm font-light text-left text-gray-800 dark:text-gray-500">
          {props.label}
        </p>
      )}
      <input
        className={classNames(
          className,
          "without-ring bg-gray-200 focus:bg-gray-300 rounded-xl text-base focus:ring-darkmode-600 border-none focus:ring-1 disabled:opacity-50 disabled:cursor-not-allowed mx-auto focus:text-gray-900 placeholder-gray-600 dark:placeholder-gray-600 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-600",
          props.darkest ? "dark:bg-newdarkmode-700" : "dark:bg-newdarkmode-700"
        )}
        ref={inputRef}
        {...omitKeys(props, ["darkest"])}
      />
    </div>
  );
};

export default TextField;
