import React from "react";

const Refresh = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} cursor-pointer`}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0.1"
    viewBox="0 0 24 24"
  >
    <path
      d="M10 11V9L5.42646 9C6.45591 6.087 9.2351 4 12.5 4C15.8729 4 18.7283 6.22731 19.6705 9.29372L19.9642 10.2496L21.876 9.66217L21.5823 8.70628C20.3894 4.82402 16.7758 2 12.5 2C8.77911 2 5.5591 4.13853 4 7.25333V3H2V10C2 10.5523 2.44772 11 3 11H10Z"
      fill="white"
    />
    <path
      d="M12.5 21C15.5495 21 18.2622 19.5635 20 17.3313V21H22V14C22 13.4477 21.5523 13 21 13H14V15H19.1353C17.8773 17.3797 15.3769 19 12.5 19C9.46032 19 6.84088 17.1916 5.66283 14.5878L5.25063 13.6767L3.42845 14.5011L3.84065 15.4122C5.33059 18.7054 8.64626 21 12.5 21Z"
      fill="white"
    />
  </svg>
);

export default Refresh;
