import React from "react";
import { Switch } from "@headlessui/react";
import { classNames } from "src/Hooks/util";

const LeftLabelToggle = ({ ...props }) => (
  <Switch.Group as="div" className="flex items-center justify-between gap-3">
    <span className="flex flex-col flex-grow font-thin">
      <Switch.Label
        as="span"
        className="text-base font-medium text-black leading-6 dark:text-white"
        passive
      >
        {props.label}
      </Switch.Label>
      <Switch.Description
        as="span"
        className="text-xs text-black xs:text-sm dark:text-gray-400"
      >
        {props.description}
      </Switch.Description>
    </span>
    <Switch
      checked={props.enabled}
      onChange={props.setEnabled}
      className={classNames(
        props.enabled
          ? "bg-green-500 dark:bg-green-500"
          : "bg-gray-400 dark:bg-newdarkmode-600",
        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
      )}
    >
      <span
        aria-hidden="true"
        className={classNames(
          props.enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-newdarkmode-900 shadow ring-0 transition duration-200 ease-in-out"
        )}
      />
    </Switch>
  </Switch.Group>
);

export default LeftLabelToggle;
