/* eslint-disable arrow-body-style */
import React from "react";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import Circles from "src/Components/Steps/Circles";

const Card1 = ({ handleChange, ...props }) => {
  return (
    <div className="w-full h-auto max-w-2xl pb-10 m-auto mx-3 text-center xs:mx-6 sm:m-auto">
      <Circles
        steps={props.steps}
        name="Secure Wallet"
        className="w-full pl-6"
      />
      <h1 className="text-2xl font-medium text-center text-gray-900 xs:text-3xl dark:text-gray-300 flex-grow-default">
        Secure Your Wallet
      </h1>
      <p className="my-3 text-base font-thin text-left text-gray-500 flex-grow-default sm:text-lg">
        Your secret seed phrase is the key to access your BitMask Wallet. Be
        sure to keep your seed phrase in a secure place.{" "}
        <span className="font-medium">
          Loss of your seed phrase means loss of access to your wallet and your
          funds!
        </span>{" "}
        Write down your seedphrase now for safekeeping with pen and paper, or
        retrieve it later in the wallet using your password.
      </p>
      <RoundedButton
        className="w-full py-1 mx-auto mt-8 text-base font-medium text-gray-900 bg-yellow-500 sm:text-lg"
        onClick={() => {
          props.setSteps([
            { name: "Set Password", href: "#", status: "complete" },
            { name: "Create Username", href: "#", status: "complete" },
            { name: "Secure Wallet", href: "#", status: "current" },
          ]);
          handleChange("confirmPhrase");
        }}
      >
        Continue
      </RoundedButton>
    </div>
  );
};

export default Card1;
