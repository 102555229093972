import React from "react";

const CheckCircle = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="currentColor"
    stroke="none"
    viewBox="0 0 88 88"
  >
    <circle
      opacity="0.96"
      cx="44"
      cy="44"
      r="44"
      fill="#FFCB12"
      fillOpacity="0.1"
    />
    <path
      d="M40.728 50.386L59.112 32L61.942 34.828L40.728 56.042L28 43.314L30.828 40.486L40.728 50.386Z"
      fill="#FFCB12"
    />
  </svg>
);

export default CheckCircle;
