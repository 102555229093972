/* eslint-disable no-unused-vars */
import React from "react";
import { RadioGroup } from "@headlessui/react";

const SideOptions = ({ ...props }) => (
  <div className="w-auto mx-auto sm:w-full">
    <RadioGroup value={props.value} onChange={props.setValue}>
      <RadioGroup.Label className="sr-only">options</RadioGroup.Label>
      <div className="shadow-lg cursor-pointer dark:bg-newdarkmode-800 dark:border-1/2 dark:border-newdarkmode-600 dark:border-opacity-25 rounded-xl divide-y-1/2 divide-newdarkmode-600">
        {props.options.map((option) => (
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={({ active, checked }) =>
              `${
                props.lessMargin ? "xs:py-3" : "xs:py-4"
              } relative pl-6 md:pl-9 lg:px-6 py-2 cursor-pointer flex focus:outline-none`
            }
          >
            {({ active, checked }) => (
              <div className="flex items-center my-auto sm:justify-between sm:w-full">
                <div className="flex items-center">
                  <div>
                    <RadioGroup.Label
                      as="div"
                      className={`font-medium flex flex-nowrap my-auto hover:gray-500 ${
                        props.value.name === option.name
                          ? "text-yellow-500"
                          : "text-gray-200"
                      }`}
                    >
                      <p className="pr-4 my-auto text-base text-left xl:text-lg">
                        {option.name}
                      </p>
                    </RadioGroup.Label>
                  </div>
                </div>
                {checked && <div className="flex-shrink-0 text-white" />}
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  </div>
);

export default SideOptions;
