import React from "react";

const ArrowDown = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 16 16"
    strokeWidth="0.1"
  >
    <path
      d="M9 12.172L14.364 6.808L15.778 8.222L8 16L0.222 8.222L1.636 6.808L7 12.172V0H9V12.172Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowDown;
