import { PlusCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import ArrowDownCircle from "src/Components/Icons/ArrowDownCircle";

const NewUser = () => {
  const navigate = useNavigate();

  return (
    <div className="h-full overflow-x-hidden overflow-y-auto">
      <div
        className="flex flex-col justify-between w-full h-auto m-auto overflow-hidden"
        style={{
          minHeight: "calc(100vh - 164px)",
        }}
      >
        <div className="flex flex-col justify-center w-full h-full p-6 pt-0 m-auto sm:p-10 xl:p-18 ">
          <h1 className="text-2xl font-medium text-center text-gray-900 xs:text-3xl dark:text-gray-300 flex-grow-default">
            Let&#39;s begin
          </h1>
          <div className="flex flex-col w-full max-w-2xl m-auto mt-6 justify-items-center gap-6">
            <div className="flex flex-col p-3 rounded-lg border-1 dark:border-darkmode-700 sm:p-6 xs:flex-row">
              <div className="flex my-auto flex-nowrap">
                <PlusCircleIcon className="w-10 h-10 my-auto mr-3 text-yellow-500 xs:h-12 xs:w-12 sm:mr-6" />
                <div className="flex flex-col">
                  <p className="text-base text-left text-white sm:text-lg">
                    New to BitMask?
                  </p>
                  <p className="text-sm font-thin text-left text-gray-500 sm:text-base">
                    Create a new wallet.
                  </p>
                </div>
              </div>
              <RoundedButton
                className="w-full px-3 py-1 my-auto mt-3 text-base font-light text-black bg-yellow-500 xs:w-auto xs:ml-auto xs:mt-auto"
                onClick={() => navigate("/walletsetup")}
              >
                Create Wallet
              </RoundedButton>
            </div>
            <div className="flex flex-col p-3 rounded-lg border-1 dark:border-darkmode-700 sm:p-6 xs:flex-row">
              <div className="flex my-auto flex-nowrap">
                <ArrowDownCircle className="w-10 h-10 my-auto mr-3 text-yellow-500 xs:h-12 xs:w-12 sm:mr-6" />
                <div className="flex flex-col">
                  <p className="text-base text-left text-white sm:text-lg">
                    Welcome Back!
                  </p>
                  <p className="text-sm font-thin text-left text-gray-500 sm:text-base">
                    import existing wallet.
                  </p>
                </div>
              </div>
              <RoundedButton
                className="w-full px-3 py-1 my-auto mt-3 text-base font-light text-white xs:w-auto xs:ml-auto bg-darkmode-700 xs:mt-auto"
                onClick={() => navigate("/walletimport")}
              >
                Import Wallet
              </RoundedButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
