import * as backend from "bitmask-core";
import { RgbValidationResponse } from "bitmask-core/rgb";

export interface transferAssetsResponse {
  // txid: string;
  sign: boolean;
  consig: string;
  txid: string;
}

export const canTransferAssets = async (
  vault: backend.bitcoin.Vault,
  asset: backend.rgb.ContractResponse,
  invoice: string,
  feeRate: number
): Promise<RgbValidationResponse> => {
  const result = await backend.rgb.canCreateTransfer(vault.private.nostrPrv, {
    invoice,
    contractId: asset.contractId,
    iface: asset.iface,
    chain: "bitcoin",
    chainFee: { feeRate },
    bitcoinChanges: [],
  });

  return result;
};

export const transferAssets = async (
  vault: backend.bitcoin.Vault,
  asset: backend.rgb.ContractResponse,
  invoice: string,
  iface: string,
  feeValue: number
): Promise<transferAssetsResponse> => {
  const { consig, txid } = (
    await backend.create_and_publish_rgb_transfer(
      vault.private.nostrPrv,
      {
        invoice,
        contractId: asset.contractId,
        iface,
        chain: "bitcoin",
        chainFee: { feeRate: feeValue },
        bitcoinChanges: [],
      },
      [
        vault.private.rgbDescriptorXprv,
        vault.private.btcDescriptorXprv,
        vault.private.btcChangeDescriptorXprv,
      ]
    )
  ).data;
  console.debug("Asset consignment:", consig);

  return {
    sign: true,
    consig,
    txid,
  };
};

export const transferAssetsWithFeeRate = async (
  vault: backend.bitcoin.Vault,
  asset: backend.rgb.ContractResponse,
  invoice: string,
  iface: string,
  feeRate: number
): Promise<transferAssetsResponse> => {
  const result = await backend.create_and_publish_rgb_transfer(
    vault.private.nostrPrv,
    {
      invoice,
      contractId: asset.contractId,
      iface,
      chain: "bitcoin",
      chainFee: { feeRate },
      bitcoinChanges: [],
    },
    [
      vault.private.rgbDescriptorXprv,
      vault.private.btcDescriptorXprv,
      vault.private.btcChangeDescriptorXprv,
    ]
  );

  const { consig, txid } = JSON.parse(result).data;
  console.debug("Asset consignment:", consig);

  return {
    sign: true,
    consig,
    txid,
  };
};
