import React from "react";

const Swap = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    stroke="currentColor"
    strokeWidth="0.1"
    viewBox="0 0 18 20"
  >
    <path
      d="M13.05 10L18 14.95L13.05 19.9L11.636 18.486L14.172 15.949L1 15.95V13.95H14.172L11.636 11.414L13.05 10ZM4.95 0L6.364 1.414L3.828 3.95H17V5.95H3.828L6.364 8.486L4.95 9.9L0 4.95L4.95 0Z"
      fill="#101010"
    />
  </svg>
);

export default Swap;
