import React from "react";

const Bitcoin = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 20 20"
  >
    <g clipPath="url(#clip0_6_688)">
      <path
        d="M19.7007 12.4191C18.3649 17.7763 12.9384 21.0365 7.58001 19.7006C2.22382 18.365 -1.03684 12.9388 0.29955 7.58198C1.63469 2.22422 7.06121 -1.03637 12.418 0.299246C17.7761 1.63486 21.0364 7.06168 19.7007 12.4191Z"
        fill="#F7931A"
      />
      <path
        d="M14.1427 8.69356C14.3334 7.29255 13.3627 6.5394 12.0353 6.03697L12.4659 4.13911L11.4146 3.85121L10.9954 5.69905C10.7191 5.62337 10.4352 5.55198 10.1531 5.48123L10.5753 3.62122L9.52464 3.33331L9.09377 5.2305C8.86501 5.17325 8.64043 5.11667 8.42245 5.05711L8.42365 5.05119L6.97383 4.65338L6.69417 5.88725C6.69417 5.88725 7.47417 6.08368 7.4577 6.09586C7.88349 6.21266 7.96044 6.52228 7.94756 6.76774L7.4571 8.9298C7.48644 8.93803 7.52448 8.94988 7.5664 8.9683C7.53136 8.95876 7.49393 8.94823 7.45531 8.93803L6.76782 11.9668C6.71572 12.1089 6.58367 12.3221 6.28604 12.2412C6.29652 12.258 5.52191 12.0316 5.52191 12.0316L5 13.354L6.36808 13.7287C6.6226 13.7988 6.87202 13.8722 7.11755 13.9413L6.68249 15.8608L7.73257 16.1487L8.16345 14.2496C8.4503 14.3351 8.72877 14.4141 9.00125 14.4885L8.57187 16.3787L9.62316 16.6666L10.0582 14.7507C11.8509 15.1235 13.1989 14.9731 13.7663 13.1914C14.2236 11.7568 13.7436 10.9293 12.8004 10.3897C13.4873 10.2157 14.0047 9.71916 14.1427 8.69356ZM11.7407 12.3948C11.4158 13.8294 9.21773 13.0539 8.50509 12.8594L9.08239 10.3163C9.79503 10.5118 12.0803 10.8987 11.7407 12.3948ZM12.0659 8.67283C11.7694 9.97777 9.93995 9.31477 9.34648 9.15223L9.86989 6.84573C10.4634 7.00827 12.3746 7.31163 12.0659 8.67283Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_688">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Bitcoin;
