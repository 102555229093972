/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import Telegram from "src/Components/Icons/Telegram";
import Twitter from "src/Components/Icons/Twitter";
import Discord from "src/Components/Icons/Discord";
import Instagram from "src/Components/Icons/Instagram";
import Modal from "src/Components/Modals/Modal";
import PrivacyPolicy from "src/Components/Modals/Children/PrivacyPolicy";
import TermsOfService from "src/Components/Modals/Children/TermsOfService";
import { classNames } from "src/Hooks/util";
import { isExtension } from "src/lib";

const Footer = () => {
  const [openTos, setOpenTos] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);

  return (
    <footer
      className={classNames(
        isExtension ? "mb-4" : "",
        "flex flex-col w-full h-auto pt-2 mt-auto sm:pt-3 bg-newdarkmode-900"
      )}
    >
      <div className="flex">
        <div className="flex-1">
          <div className="text-lg cursor-pointer text-newdarkmode-600 hover:text-gray-500 md:text-left">
            <div className="flex flex-col w-full px-6 m-auto max-w-7xl md:flex-nowrap md:flex-row">
              <div className="flex flex-col w-full mt-3 font-thin md:my-auto lg:flex-row gap-9">
                <div className="flex flex-row justify-center m-auto md:ml-0 md:mr-auto gap-6 sm:my-auto sm:gap-8">
                  <div className="flex flex-col">
                    <button
                      className="mx-auto text-gray-500"
                      type="button"
                      onClick={() => setOpenPrivacyPolicy(true)}
                    >
                      <p className="text-sm">Privacy Policy</p>
                    </button>
                  </div>
                  <div className="flex flex-col">
                    <button
                      className="mx-auto text-gray-500"
                      type="button"
                      onClick={() => setOpenTos(true)}
                    >
                      <p className="text-sm">Terms of Service</p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex m-auto my-6 font-normal cursor-pointer flex-nowrap md:my-0 md:ml-auto">
                <a
                  className="w-16 m-auto mr-0 xs:w-24"
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/bitmask_app?s=21&t=VH4nq5qpcV5iPRa2Z4gEyA"
                >
                  <Twitter className="w-10 h-10 mx-auto text-gray-500  sm:w-10 sm:h-10 md:mr-0 md:ml-auto" />
                </a>
                <a
                  className="w-16 m-auto mr-0 xs:w-24"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/tryDIBA"
                >
                  <Telegram className="mx-auto text-gray-500  w-14 h-14 sm:w-14 sm:h-14 md:mr-0 md:ml-auto" />
                </a>
                <a
                  className="w-16 m-auto mr-0 xs:w-24"
                  target="_blank"
                  rel="noreferrer"
                  href="https://discord.gg/RzKxTJg24K"
                >
                  <Discord className="w-8 h-8 mx-auto text-gray-500 sm:w-8 sm:h-8 md:mr-0 md:ml-auto" />
                </a>
                <a
                  className="w-16 m-auto mr-0 xs:w-24"
                  target="_blank"
                  rel="noreferrer"
                  href="https://instagram.com/trydiba?igshid=YWJhMjlhZTc="
                >
                  <Instagram className="w-8 h-8 mx-auto text-gray-500 sm:w-8 sm:h-8 md:mr-0 md:ml-auto" />
                </a>
              </div>
            </div>
            <div className="w-full py-2 bg-darkmode-800 md:mt-3">
              <div className="flex w-full px-6 m-auto text-sm font-thin text-gray-500 flex-nowrap max-w-7xl">
                <p className="my-auto mr-auto text-gray-500">
                  &#169; 2024 BitMask
                </p>
                <p className="my-auto ml-auto text-gray-500">
                  Built with love by{" "}
                  <a href="https://diba.io" target="_blank" rel="noreferrer">
                    DIBA
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={openTos} setOpen={setOpenTos}>
        <TermsOfService />
      </Modal>
      <Modal open={openPrivacyPolicy} setOpen={setOpenPrivacyPolicy}>
        <PrivacyPolicy />
      </Modal>
    </footer>
  );
};

export default Footer;
