import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const AuthWrapper = () => {
  const lockWallet = localStorage.getItem("lockWallet");

  return !lockWallet ? <Outlet /> : <Navigate to="/signin" replace />;
};

export default AuthWrapper;
