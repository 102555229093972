/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useState } from "react";
import * as backend from "bitmask-core";
import { CARBONADO, init } from "bitmask-core/constants";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import Circles from "src/Components/Steps/Circles";
import TextField from "src/Components/Inputs/TextField";
import TermsOfService from "src/Components/Modals/Children/TermsOfService";
import Modal from "src/Components/Modals/Modal";
import useWindowSize from "src/Hooks/useWindowSize";
import { termsOfService } from "src/constants";
import { setHash } from "src/Hooks/util";
import { set } from "idb-keyval";

const CreatePassword = ({ handleChange, ...props }) => {
  const size = useWindowSize();
  const [agree, setAgree] = useState(false);
  const [_error, setError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [openTos, setOpenTos] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitPassword = () => {
    props.setSteps([
      { name: "Set Password", href: "#", status: "complete" },
      { name: "Create Username", href: "#", status: "current" },
      { name: "Secure Wallet", href: "#", status: "upcoming" },
    ]);
    handleChange("createUsername");
  };

  const handleSubmit = async () => {
    if (agree) {
      setLoading(true);
      const { password, confirmPassword } = props;
      if (password === confirmPassword && password.length > 7) {
        setError(false);
        try {
          const hash = backend.bitcoin.hashPassword(password);
          const createWatcher = async (
            nostrKey: string,
            xpub: string,
            network: string
          ): Promise<void> => {
            if (CARBONADO) {
              const result = await backend.rgb.createWatcher(nostrKey, {
                force: true,
                name: "default",
                xpub,
              });

              console.debug(
                `watcher created for ${network} network. current network: ${result.network}`
              );
            } else {
              console.debug(`cannot be create watcher for ${network} network`);
            }
          };
          const createSeed = async () => {
            const network = backend.constants.Network.signet;
            await backend.constants.switchNetwork(network);
            window.localStorage.setItem("network", network);

            await init();
            const encryptedDescriptors = await backend.bitcoin.newWallet(
              hash,
              ""
            );
            const { mnemonic } = await backend.bitcoin.decryptWallet(
              hash,
              encryptedDescriptors
            );
            return mnemonic;
          };
          const createTestnetDescriptor = async (mnemonic: string) => {
            const network = backend.constants.Network.testnet;
            await backend.constants.switchNetwork(network);
            window.localStorage.setItem("network", network);

            const encryptedDescriptors = await backend.bitcoin.encryptWallet(
              mnemonic.trim(),
              hash,
              ""
            );
            window.localStorage.setItem(
              "testnetDescriptor",
              encryptedDescriptors
            );
            const vault = await backend.bitcoin.decryptWallet(
              hash,
              encryptedDescriptors
            );
            await setHash(hash, vault.public.xpubkh);

            await createWatcher(
              vault.private.nostrPrv,
              vault.public.rgbDescriptorXpub,
              network
            );
          };

          const createSignetDescriptor = async (mnemonic: string) => {
            const network = backend.constants.Network.signet;

            await backend.constants.switchNetwork(network);
            window.localStorage.setItem("network", network);

            const encryptedDescriptors = await backend.bitcoin.encryptWallet(
              mnemonic.trim(),
              hash,
              ""
            );
            window.localStorage.setItem(
              "signetDescriptor",
              encryptedDescriptors
            );
            const vault = await backend.bitcoin.decryptWallet(
              hash,
              encryptedDescriptors
            );

            await createWatcher(
              vault.private.nostrPrv,
              vault.public.rgbDescriptorXpub,
              network
            );
          };
          const createRegtestDescriptor = async (mnemonic: string) => {
            const network = backend.constants.Network.regtest;
            await backend.constants.switchNetwork(network);
            window.localStorage.setItem("network", network);

            const encryptedDescriptors = await backend.bitcoin.encryptWallet(
              mnemonic.trim(),
              hash,
              ""
            );
            window.localStorage.setItem(
              "regtestDescriptor",
              encryptedDescriptors
            );
            const vault = await backend.bitcoin.decryptWallet(
              hash,
              encryptedDescriptors
            );

            await createWatcher(
              vault.private.nostrPrv,
              vault.public.rgbDescriptorXpub,
              network
            );
          };
          const createBitcoinDescriptor = async (mnemonic: string) => {
            const network = backend.constants.Network.bitcoin;
            await backend.constants.switchNetwork(network);
            window.localStorage.setItem("network", network);
            await init();
            const encryptedDescriptors = await backend.bitcoin.encryptWallet(
              mnemonic.trim(),
              hash,
              ""
            );
            window.localStorage.setItem(
              "bitcoinDescriptor",
              encryptedDescriptors
            );
            const vault = await backend.bitcoin.decryptWallet(
              hash,
              encryptedDescriptors
            );

            await createWatcher(
              vault.private.nostrPrv,
              vault.public.rgbDescriptorXpub,
              network
            );
            await backend.lightning.createWallet(
              vault.public.xpubkh,
              vault.private.xprvkh
            );
            props.setVault(vault);
            props.setMnemonic(vault.mnemonic.split(" ").slice(0, 24));
            console.debug("got mnemonic");
            submitPassword();
          };
          const mnemonic = await createSeed();
          await createTestnetDescriptor(mnemonic);
          await createSignetDescriptor(mnemonic);
          await createRegtestDescriptor(mnemonic);
          await createBitcoinDescriptor(mnemonic);
        } catch (error) {
          setLoading(false);
          console.error("error in CreatePassword", error);
          setError(true);
        }
      } else {
        setLoading(false);
        setError(true);
      }
    } else {
      setTermsError(true);
    }
  };

  return (
    <div className="w-full h-auto max-w-2xl pb-10 m-auto mx-3 text-center xs:mx-6 sm:m-auto">
      <Circles
        steps={props.steps}
        name="Create Password"
        className="w-full pl-9"
      />
      <h1 className="text-2xl font-medium text-center text-gray-900 xs:text-3xl dark:text-gray-300 flex-grow-default">
        Create Password
      </h1>
      <p className="w-full m-auto my-3 text-base font-thin text-left text-gray-500 sm:text-lg flex-grow-default leading-7 sm:leading-8">
        Your password allows you to log in to your BitMask account. This is
        different from your seed phrase.
      </p>
      <TextField
        type="password"
        className="w-full p-3 mt-2"
        placeholder="Create Password"
        label="Create Password"
        autoComplete="new-password"
        onChange={(e) => props.setPassword(e.target.value)}
        darkest={size.width >= 500}
      />
      {props.password.length < 8 && props.password !== "" && (
        <div className="w-full m-auto">
          <p className="-mb-2 text-sm text-left text-red-600 flex-grow-default">
            Must be 8 characters
          </p>
        </div>
      )}
      <TextField
        type="password"
        className="w-full p-3 mt-2"
        placeholder="Confirm Password"
        label="Confirm Password"
        autoComplete="new-password"
        onChange={(e) => props.setConfirmPassword(e.target.value)}
        onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : null)}
        darkest={size.width >= 500}
      />
      {props.password !== props.confirmPassword &&
        props.confirmPassword !== "" && (
          <div className="w-full m-auto">
            <p className="text-sm font-semibold text-left text-red-600 flex-grow-default">
              passwords must match
            </p>
          </div>
        )}
      {termsError && (
        <div className="w-full m-auto">
          <p className="text-sm font-semibold text-left text-red-600 flex-grow-default">
            You must agree to the Terms of Service to create an account.
          </p>
        </div>
      )}
      <div className="flex w-full pl-1 m-auto mt-5 flex-nowrap">
        <input
          type="checkbox"
          className="w-5 h-5 my-auto mr-3"
          onChange={() => {
            setAgree(!agree);
            setTermsError(false);
          }}
          onKeyDown={(e) =>
            e.key === "Enter"
              ? () => {
                  setAgree(!agree);
                  setTermsError(false);
                }
              : null
          }
        />
        <p className="my-auto text-sm font-normal text-left text-gray-900 dark:text-gray-400">
          Click to agree to our{" "}
          <button
            type="button"
            className="text-gray-700 dark:text-yellow-500 rounded-xl"
            onClick={() => setOpenTos(true)}
          >
            Terms of Service.
          </button>
        </p>
      </div>
      <RoundedButton
        className="w-full py-1 mx-auto mt-8 text-base font-medium text-gray-900 bg-yellow-500 sm:text-lg"
        onClick={() => handleSubmit()}
        loading={loading}
      >
        Continue
      </RoundedButton>
      <Modal open={openTos} setOpen={setOpenTos}>
        <TermsOfService />
      </Modal>
    </div>
  );
};

export default CreatePassword;
